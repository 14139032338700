import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import commonMethod from "./Common-methods";
import HandlebarsTemplate from "./Handlebars-template";

class SingleStaff {
  constructor() {
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.commonMethod = new commonMethod(); // instance of common methods
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
  }

  // Fetch staff list by location
  async fetchStaffByLocation() {
    try {
      const { selectedLocation, onboardingData } = this.APOData;
      const filterLocation = {
        locationNames: [
          {
            id: selectedLocation.id,
            name: selectedLocation.locationName,
          },
        ],
      };

      // Fetch business hours based on location and staff ID
      const response = await this.apiHandler.PostAPI_PutAPI_method(
        `FilterByLocation`,
        "POST",
        filterLocation
      );

      onboardingData.staffMembersList = response?.data ?? [];
    } catch (error) {
      console.error("Error fetching staff list By Location:", error);
    }
  }

  // Fetch business hours by location
  async fetchStaffHoursByLocation(staffId, locId) {
    try {
      // Fetch business hours based on location and staff ID
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        `BusinessHoursByLocation?LocationId=${locId}&StaffId=${staffId}`,
        "GET"
      );

      // Validate response structure
      return response?.data.businessHoursLists ?? [];
    } catch (error) {
      console.error("Error fetching hours By Location:", error);
    }
  }

  async fetchProviderCountData(locId) {
    try {
      // Select the booking content element
      const APO__content = document.querySelector(".APO__content");
      
      // Display placeholder loading
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.placeholderLoading_boxedlayout(),
        ""
      );

      // Fetch provider count data
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        "ProviderCount",
        "GET"
      );
      const providers = response?.data ?? [];

      // Return early if no provider data is available
      if (!providers.length) {
        console.warn("No provider data available.");
        return [];
      }

      // Filter providers by location ID and sort by totalCount (ascending)
      const sortedProviders = providers
        .filter((provider) =>
          provider.staffLocation.some((location) => location.id === locId)
        )
        .sort((a, b) => a.totalCount - b.totalCount);

      return sortedProviders;
    } catch (error) {
      console.error("Error fetching provider count data:", error.message);
      return [];
    }
  }
}

// Export the AppointmentData
export default SingleStaff;
