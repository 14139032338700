import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import HandlebarsTemplate from "./Handlebars-template";
import HandlebarsHelper from "./Handlebars-helper";
import FormValidation from "./FormValidation";
import commonMethod from "./Common-methods";
import BookingRules from "./BookingRules";
import moment from "moment";
import StripePayment from "./StripePayment";

class PaymentDetail {
  constructor() {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars template
    this.handlebarsHelper = new HandlebarsHelper(); // instance of Handlebars template
    this.commonMethod = new commonMethod(); // instance of common method
    this.bookingRules = new BookingRules(this); // Booking rule instance
    this.formValidation = new FormValidation(); // instance of Handlebars template
    this.gblProps = new GlobalProps(); // instance of gobal props class
    this.APOData = this.gblProps.APOData; // appointment Data

    this.stripePayment = new StripePayment(this);

    this.bindMethods();
  }

  bindMethods() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
    window.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this); // Bind change payment Method
  }

  handlePaymentMethodChange = (event) => {
    // Destructure necessary properties from  APOData
    const { stripePaymentElement } = this.APOData;
  
    const btnAPOcheckout = document.querySelector(".btn-APOcheckout");
    const bindpaymentElement = document.querySelector(".bindpaymentElement");
    const value = event.target.value;
  
    // appointment booking/pay with satus
    this.APOData.selectedPaymentMethod = value;
  
    if (value === "skip_payment") {
      btnAPOcheckout.textContent = "Confirm";
      if (stripePaymentElement) {
        const errorMessage = document.getElementById("error-message");
        errorMessage.classList.add("hidden");
        stripePaymentElement.unmount();
        bindpaymentElement.querySelector(".thirdpartypaymentmethod").remove();
      }
    } else {
      btnAPOcheckout.textContent = "Pay & confirm";
      const html = `<div class="thirdpartypaymentmethod"><div id="payment-element"><span class="APO__loader APO__spin block APO-mx-auto" style="width: 40px;"></span></div>`;
      bindpaymentElement.insertAdjacentHTML("beforeend", html);
      this.stripePayment.setupStripe(); // setup Stripe payment method
    }
  };

  async showPaymentMode() {
    const { isPayPalConnected, isStripeConnected, isCashConnected } =
      this.APOData.paymentModeList;

    if (isPayPalConnected) {
      console.log("isCashConnected and isStripeConnected");
      // setupCashPayment(); // Call the function to handle cash payment setup if applicable
    } else if (isStripeConnected) {
        this.stripePayment.setupStripe(); // setup Stripe payment method
    } else {
      await this.APO__bookappointment(false);
    }
  }

  async APO__bookappointment() {
    const APO__screenmask = document.querySelector(".APO__screenmask");
    const APO__loader = document.querySelector(".APO__loader");

    // Destructure necessary properties from APOData
    const { userDetail, APO_selectedData, reschedule, bookingId, APOtype, selectedLocation, lang, widgetLanguage } = this.APOData;
    const { APOConfirmedScreen, buttonText, eventLocationType } = widgetLanguage[lang];

    // Filter booking rules to get cancellation and rescheduling rules
    const appointmentButtonKeys = ["IsBookAppointmentButton"];
    const IsBookAppointmentButton = await this.bookingRules.filterBookingRule(appointmentButtonKeys);

    // Show screen mask and loader
    this.commonMethod.showLoader(APO__screenmask);
    try {
      // Create customer record
      const customerResponse = await this.apiHandler.PostAPI_PutAPI_method(
        "Customer",
        "POST",
        userDetail
      );

      if (!customerResponse || customerResponse.status !== 200) {
        throw new Error("Failed to create customer.");
      }

      let appointmentResponse = null;

      if (APOtype === "GlobalEvents" || APOtype === "Class") { 
        // Prepare appointment details
        const eventObj = this.prepareEventObject(customerResponse.data.id);
  
        // Book the event
        appointmentResponse = await this.apiHandler.PostAPI_PutAPI_method(
          "PurchaseTickets",
          "POST",
          eventObj
        );
      }
      else {
        // Prepare appointment details
        const appointmentsObj = this.prepareAppointmentObject(customerResponse.data.id);
  
        // booking ID when Reschedule appointment
        reschedule ? (appointmentsObj.id = bookingId) : "";
  
        // Book the appointment
        appointmentResponse = await this.apiHandler.PostAPI_PutAPI_method(
          "Appointments",
          reschedule ? "PUT" : "POST",
          appointmentsObj
        );
      }


      if (appointmentResponse.status === 200) {
        // Prepare data for the template
        const templateData = {
          ...APO_selectedData,
          bookingStatus: appointmentResponse.data,
          selectedLocation,
          IsBookAppointmentButton,
          lang: {...APOConfirmedScreen, ...buttonText, ...eventLocationType}
        };

        this.handleSuccessfulBooking(
          APO__loader,
          APO__screenmask,
          templateData
        );

        this.APOData.Service = [];
        this.APOData.GlobalEvents = [];
        this.APOData.Class = [];


      } else {
        this.APO_bookingErrorHandle(APO__loader, APO__screenmask);
      }
    } catch (error) {
      console.error("Booking error:", error);
      this.APO_bookingErrorHandle(APO__loader, APO__screenmask);
    }
  }

  // Prepare appointment object based on the selected data
  prepareAppointmentObject(customerId) {
    // Destructure necessary properties from APOData
    const { userDetail, APO_selectedData, APOtype, AllcustomerID, selectedLocation, businessTimeZone } = this.APOData;
    const { appointmentJSON, staffJSON, timeslot } = APO_selectedData;
    const {
      title,
      duration,
      price,
      currencySymbol,
    } = appointmentJSON;

    // Initialize appointment date strings
    let startDateStr = "";
    let endDateateStr = "";

    const totalDuration = this.handlebarsHelper.convertTimeToMinutes(duration);
    const addDuration = moment.unix(timeslot).add(totalDuration, "minutes");
    startDateStr = this.handlebarsHelper.convertUnixTimestamp(
      timeslot,
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    endDateateStr = this.handlebarsHelper.convertUnixTimestamp(
      addDuration.unix(),
      "YYYY-MM-DDTHH:mm:ssZ"
    );

    // Prepare appointment object
    return {
      title: `${title} with ${userDetail.name}`,
      serviceName: title,
      startDate: startDateStr,
      endDate: endDateateStr,
      timeZone: businessTimeZone,
      customerList: [
        {
          id: customerId,
          name: userDetail.name,
          emailAddress: userDetail.emailAddress,
          image: "",
        },
      ],
      listId: AllcustomerID,
      text: "Booking from Plugin",
      color: appointmentJSON.color,
      eEventType: "Appointment",
      eNotificationType: "Email",
      serviceId: appointmentJSON.id,
      staffId: staffJSON.id,
      staffName: staffJSON.name,
      price: price,
      currencySymbol: currencySymbol,
      duration: duration,
      locationId: selectedLocation.id 
    };
  }

  prepareEventObject (customerId){
    // Destructure necessary properties from APOData
    const { userDetail, APO_selectedData, AllcustomerID, guestEmails } = this.APOData;
    const { appointmentJSON, bookSlotQuantity } = APO_selectedData;
  
    // Prepare appointment object
    return {
      eventId: appointmentJSON.id,
      ticketCount: bookSlotQuantity,
      listId: AllcustomerID,
      customerList: [
        {
          id: customerId,
          name: userDetail.name,
          emailAddress: userDetail.emailAddress,
          image: "",
        },
      ],
      guestList: guestEmails ? guestEmails : [],
    };
  }

  // Handle booking errors
  APO_bookingErrorHandle(loader, screenmask) {
    loader.classList.remove("APO__spin");
    loader.classList.add("APO__failCheck");

    setTimeout(() => {
      this.commonMethod.hidescreenmask(loader, screenmask, "APO__failCheck");
    }, 2000);
  }

  // Handle successful booking
  handleSuccessfulBooking(loader, screenmask, APO_selectedData) {
    loader.classList.remove("APO__spin");
    loader.classList.add("APO__successCheck");

    setTimeout(() => {
      const APO__content = document.querySelector(".APO__content");
      this.commonMethod.hidescreenmask(loader, screenmask, "APO__successCheck");
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.template_appointmentConfirm(),
        APO_selectedData
      );
    }, 2000);
  }
}

export default PaymentDetail;
