import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import HandlebarsTemplate from "./Handlebars-template";
import FormValidation from "./FormValidation";
import commonMethod from "./Common-methods";
import BookingRules from "./BookingRules";
import moment from "moment";

class SearchBooking {
  constructor() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
    this.formValidation = new FormValidation(); // instance of Handlebars template
    this.commonMethod = new commonMethod(); // instance of common methods
    this.bookingRules = new BookingRules(this); // Booking rule instance
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    window.searchAppointmentFrom = this.searchAppointmentFrom.bind(this); // checkout form
  }

  findBookingFormListeners() {
    const findBookingForm = document.getElementById("bookedApo_searchFrom");
    const submitButton = findBookingForm.querySelector('button[type="submit"]');

    // Define validation schema for form fields
    const validationSchema = {
      bookingInfo: [{ type: "isRequired" }],
    };

    // Common validation handler function
    const handleValidation = (event) => {
      const { name, value } = event.target;

      if (validationSchema[name]) {
        const { isValid, errorMessage } = this.formValidation.validateField(
          name,
          value,
          validationSchema
        );
        //showValidationMessages(event.target, errorMessage);
        this.formValidation.toggleSubmitButton(
          findBookingForm,
          validationSchema,
          submitButton
        );
      }
    };

    // Attach event listeners to the form (event delegation)
    findBookingForm.addEventListener("keyup", handleValidation);
  }

  async searchAppointmentFrom(event) {
    event.preventDefault(); // Prevent default form submission

    // Select the booking content element
    const APO__SearchResult = document.querySelector(".APO__SearchResult");

    // Show loader on the clicked element
    this.commonMethod.setBtnLoader(true, event.submitter, [27]);

    const formData = new FormData(event.target);
    const data = {
      searchText: formData.get("bookingInfo"),
      tokenId: this.APOData.key,
    };

    //clear search result div
    APO__SearchResult.innerHTML = "";

    const result = await this.searchbookedappointment(data);
    event.target.reset(); // Reset form on successful submission

    // Hide the loader on the button after API call is complete
    this.commonMethod.setBtnLoader(false, event.submitter);
  }

  // Function to search appointments with provided data
  async searchbookedappointment(postData) {
    // Destructure necessary properties from APOData
    const { lang, widgetLanguage } = this.APOData;
    const { APOConfirmedScreen } = widgetLanguage[lang];

    let statusMessage = null;
    this.APOData.SearchBookingResult = [];

    try {
      const response = await this.apiHandler.PostAPI_PutAPI_method(
        "SearchAppointments",
        "POST",
        postData
      );


      if (response.status === 200 && response.data?.length) {
        this.APOData.SearchBookingResult = response.data
          .filter(
            (appointment) => appointment.eEventType === "Appointment"
          )
          .map((appointment) => ({
            ...appointment,
            isOldAppointment: moment().isBefore(moment(appointment.startDate)),
          }));
      } else {
        statusMessage = APOConfirmedScreen.errorType1;
      }

      return response;
    } catch (error) {
      console.error("Error in finding booking:", error);
    } finally {
      statusMessage = APOConfirmedScreen.errorType2;
      this.bookedAppointmentsList(statusMessage, APOConfirmedScreen); // Display error
    }
  }

  async bookedAppointmentsList(message, APOConfirmedScreen_lang) {
    // Select the booking content element
    const APO__SearchResult = document.querySelector(".APO__SearchResult");

    try {
      // Display loading placeholder while fetching the data
    //   this.commonMethod.updateHandlebarsTemp(
    //     APO__SearchResult,
    //     this.handlebarsTemplate.placeholderLoading_bookedList(),
    //     ""
    //   );

      // Filter booking rules to get cancellation and rescheduling rules
      const allowedKeys = [
        "IsAllowOnlineRescheduling",
        "IsAllowOnlineCancellation",
      ];
      const rule_CancelResdul = await this.bookingRules.filterBookingRule(
        allowedKeys
      );

      // Prepare data for the template
      const templateData = {
        BookingData: this.APOData.SearchBookingResult,
        StatusMessage: message,
        rule_CancelResdul,
        lang: APOConfirmedScreen_lang
      };

      // Render the booked appointment list template with the fetched data
      this.commonMethod.updateHandlebarsTemp(
        APO__SearchResult,
        this.handlebarsTemplate.template_bookedAppointmentList(),
        templateData
      );
    } catch (error) {
      console.error("Failed to fetch booked appointments:", error);
      // Handle error appropriately (e.g., show an error message)
    }
  }
}

// Export the AppointmentData
export default SearchBooking;
