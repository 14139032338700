import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import HandlebarsTemplate from "./Handlebars-template";
import FormValidation from "./FormValidation";
import PaymentDetail from "./PaymentDetail";
import commonMethod from "./Common-methods";
import { v4 as uuidv4 } from "uuid";

class BookingForm {
  constructor() {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars template
    this.formValidation = new FormValidation(); // instance of Handlebars template
    this.paymentDetail = new PaymentDetail(); // instance of Handlebars template
    this.commonMethod = new commonMethod(); // instance of common methods
    this.gblProps = new GlobalProps(); // instance of gobal props class
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
    window.submitBookingContactForm = this.submitBookingContactForm.bind(this); // checkout form
    window.updateSlotsQuantityCount = this.updateSlotsQuantityCount.bind(this); // Quantity count
    window.handleGuestEmailKeyUp = this.handleGuestEmailKeyUp.bind(this); // Guest email KeyUp
    window.handleGuestEmailBlur = this.handleGuestEmailBlur.bind(this); // Guest email Blur
    window.removeGuestEmail = this.removeGuestEmail.bind(this); // Remove Guest email
  }

  async handleGuestEmailKeyUp(event) { 
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleGuestEmailBlur(event);
    }
  }

  handleGuestEmailBlur(event) {
    const { target, key } = event;
    const { APOtype, guestEmails, APO_selectedData, lang, widgetLanguage } = this.APOData;
    const {validationMessage} =  widgetLanguage[lang];
    const wrapper = target.closest(".APO-guestsEmail-wrapper");
  
    // Define validation schemas for guests email
    const validationSchema = {
      inviteGuest: [{ type: "isEmail" }],
    };
  
    target.readOnly = (key !== "Enter");
  
    const { name, value } = target;
    // Prevent empty input
    if (!value.trim()) {
      // Clear previous error messages for this field
      const existingError = wrapper.parentElement.querySelector(".has-error");
      if (existingError) existingError.remove();
      return;
    } 
  
    const guestMaxlength = APO_selectedData.bookSlotQuantity;
  
    const { isValid } = this.formValidation.validateField(name, value, validationSchema);
    const isEmailDuplicate = guestEmails.some((item) => item.emailAddress === value);
    const errorMessage = isEmailDuplicate
      ? validationMessage.emailAlreadyExist
      : guestEmails.length >= guestMaxlength ? validationMessage.guestLimit : !isValid
      ? validationMessage.invalidEmail
      : "";
  
      this.formValidation.showValidationMessages(wrapper, errorMessage);
    if (!isValid || isEmailDuplicate || guestEmails.length >= guestMaxlength) return;
  
    const uid = uuidv4();
  
    // Create email wrapper div
    const guestEmailDiv = document.createElement("div");
    guestEmailDiv.className =
      "APO-guest-valid-email flex items-center flex-shrink max-w-full APO-rounded";
    guestEmailDiv.innerHTML = `<div class="APO-py-2 APO-pl-2">${value}</div>
    <a href="javascript:void(0)" class="APO-p-2 cursor-pointer flex" data-id="${uid}" onclick="removeGuestEmail(event)" style="font-size: 14px;">
      <svg class="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
    </a>`;
  
    // Insert the new email div before the input field
    wrapper.insertBefore(guestEmailDiv, target);
  
    guestEmails.push({ id: uid, emailAddress: value });
  
    // Clear input value
    target.value = "";
  };

  removeGuestEmail(event) {
    const { currentTarget } = event;
    const { guestEmails } = this.APOData;
    const { id } = currentTarget.dataset;
  
    // Remove guest from the list
    this.APOData.guestEmails = guestEmails.filter((item) => item.id !== id);
  
    // Remove element from the DOM
    currentTarget.closest(".APO-guest-valid-email")?.remove();
  };

  bookingForm_EventListeners() {
    // Destructure necessary properties from APOData
    const { CountriesList } = this.APOData;

    const bookingContactForm = document.getElementById("bookingContactForm");
    const APO__bookingBtn = bookingContactForm.querySelector("#bookingContactFormBtn");

    // Validation schema
    const validationSchema = {
      name: [{ type: "isRequired" }],
      email: [{ type: "isRequired" }, { type: "isEmail" }],
      number: [
        { type: "isRequired" },
        { type: "isMobilePhone", locale: "any" },
      ],
      country_code: [
        { type: "isRequired" },
        { type: "isIn", options: CountriesList.map((list) => list.phone_code) },
      ],
      country: [
        { type: "isRequired" },
        { type: "isIn", options: CountriesList.map((list) => list.name) },
      ],
    };

    // Debounce function to throttle validation calls
    const debounce = (fn, delay = 300) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
      };
    };

    // Common event handler for form validation
    const handleFieldChange = debounce((event) => {
      const { name, value } = event.target;

      // Validate the specific field
      const { isValid, errorMessage } = this.formValidation.validateField(
        name,
        value,
        validationSchema
      );

      // Show validation message and update form state
      this.formValidation.showValidationMessages(event.target, errorMessage);
      this.formValidation.toggleSubmitButton(
        bookingContactForm,
        validationSchema,
        APO__bookingBtn
      );
    });

    // Attach event listeners
    bookingContactForm.addEventListener("keyup", handleFieldChange);

    // Custom select field using jQuery
    $(".jqueryCustomSelect").on("change", handleFieldChange);

    // Clean up event listeners if needed (in case of form reset, page navigation, etc.)
    this.removeEventListeners = () => {
      bookingContactForm.removeEventListener("keyup", handleFieldChange);
      $(".jqueryCustomSelect").off("change", handleFieldChange);
    };
  }

  async submitBookingContactForm(event) {
    event.preventDefault(); // Prevent default form submission

    // Show loader on the clicked element
    this.commonMethod.setBtnLoader(true, event.submitter, [27]);

    // Find the form element (assuming button is inside the form)
    const formEle = event.currentTarget.closest("#bookingContactForm");
    if (!formEle) {
      console.error("Form not found");
      return;
    }

    const formData = new FormData(formEle);
    this.APOData.userDetail = {
      name: formData.get("name"),
      emailAddress: formData.get("email"),
      phoneNumber: "+" + formData.get("country_code") + formData.get("phoneNumber"),
      country: formData.get("country"),
      listId: this.APOData.AllcustomerID,
    };

    this.paymentDetail.showPaymentMode();
  }

  async updateSlotsQuantityCount(event) {
    // Destructure necessary properties from StyleObj and APOData
    const { APO_selectedData, lang, widgetLanguage } = this.APOData;;
    const { appointmentJSON } = APO_selectedData;
    const { validationMessage } = widgetLanguage[lang];

    const { currentTarget } = event;
    let numberField = currentTarget.parentElement.querySelector(
      "input[type='number']"
    );

    // update order price and quantity
    let totalPrice = document.querySelectorAll(".APO-totalPrice");

    let currentValue = parseInt(numberField.value, 10); // Default to 1 if NaN;
    let maxLimit = parseInt(numberField.getAttribute("maxlength"), 10); // Default max if not set

    if (currentTarget.classList.contains("btn-plus")) {
      // Increase value but don't exceed max limit
      if (currentValue < maxLimit) {
        numberField.value = currentValue + 1;
      } else {
        const errorMessage = `${validationMessage.maxBookingSlots} ${maxLimit}.`
        this.formValidation.showValidationMessages(currentTarget.parentElement, errorMessage);
      }
    } else {
      // Decrease value but keep at minimum 1
      if (currentValue > 1) numberField.value = currentValue - 1;

      // Clear previous error messages for this field
      const existingError = currentTarget.parentElement.parentElement.querySelector(".has-error");
      if (existingError) existingError.remove();
    }

    // Update book slot quantity
    Object.assign(APO_selectedData, {
      bookSlotQuantity: numberField.value,
    });

    // Reflect updated value in UI
    totalPrice.forEach(
      (item) =>
        (item.textContent =
          parseInt(appointmentJSON.price) * APO_selectedData.bookSlotQuantity)
    );
  }
}

export default BookingForm;
