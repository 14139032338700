import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import commonMethod from "./Common-methods";
import HandlebarsTemplate from "./Handlebars-template";
import SingleStaff from "./SingleStaff";
import ProductCategory from "./ProductCategory";

class MultiLocation {
  constructor() {
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.commonMethod = new commonMethod(); // instance of common methods
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
    this.singleStaff = new SingleStaff(); // instance of single staff
    this.productCategory = new ProductCategory(this); // product Category instance

    this.bindMethods();
  }

  bindMethods() {
    window.APO__chooseLocation = this.APO__chooseLocation.bind(this); // Bind method class instance

    //svg icon's
    this.ANGLELEFT = process.env.ANGLELEFT;
  }

  async fetchAllLocations() {
    try {
      // Destructure necessary data from APOData
      let { onboardingData } = this.APOData;

      this.APOData.locationList = onboardingData.businessAddressList;

      // Fetch location list
      // const response = await this.apiHandler.GetAPI_deleteAPI_method(
      //   `LocationFilter`,
      //   "GET"
      // );

      //Validate response structure
      // this.APOData.locationList = response?.data ?? [];

      //Updates the location in APOData if a single location exists
      let { locationList } = this.APOData;
      if (locationList.length === 1) {
        this.APOData.selectedLocation = locationList[0];
        // Fetch staff by location
        await this.singleStaff.fetchStaffByLocation();
      }

      return locationList;
    } catch (error) {
      console.error("Error fetching Location list:", error);
    }
  }

  async bindLocationLayout(locationList) {
    try {
      // Destructure necessary data from APOData
      let { APOtypeList, lang, widgetLanguage } = this.APOData;

      // Select the booking content element
      const APO__content = document.querySelector(".APO__content");

      // update the header title
      this.commonMethod.updateBoxTitle(widgetLanguage[lang].locationScreen.headerTitle);

      // update step
      this.APOData.step = APOtypeList.length > 1 ? 1 : 0;

      // Prepare the data for the Handlebars template
      const templateData = {
        goBackStep: this.APOData.step,
        locationList,
      };

      // Compile and update the Handlebars template
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.template_APOLocationList(),
        templateData
      );
    } catch (error) {
      console.error("Error in bindLocationLayout:", error);
    }
  }

  async APO__chooseLocation(event) {
    try {
      // Extract the current target from the event
      const { currentTarget } = event;
      

      // Select the booking content element
      const APO__content = document.querySelector(".APO__content");

      // Display placeholder loading
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.placeholderLoading_bookedList(),
        ""
      );

      // Destructure necessary data from APOData
      const { locationList, APOtype } = this.APOData;
      const locId = currentTarget.dataset.id;

      // Find the selected location by ID
      this.APOData.selectedLocation = locationList.find(
        (item) => item.id === locId
      );

      // Fetch staff by location and update the appointment layout
      if(APOtype === "Service") await this.singleStaff.fetchStaffByLocation();
      await this.productCategory.APO__AppointmentLayout(this.APOData[APOtype]);
    } catch (error) {
      console.error("Error in APO__chooseLocation:", error.message);
    }
  }
}

// Export the AppointmentData
export default MultiLocation;
