import APIHandler from "./Api";
import HandlebarsTemplate from "./Handlebars-template";
import commonMethod from "./Common-methods";
import BookingRules from "./BookingRules";
import ProductCategory from "./ProductCategory";
import SearchBooking from "./SearchBooking";
import GlobalProps from "./GlobalProps";
import MultiLocation from "./MultiLocation";
import { find } from "lodash-es";

class BusinessData {
  constructor(bookingInstance) {
    this.booking = bookingInstance; // use the passed Booking instance
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
    this.commonMethod = new commonMethod(); // instance of common methods
    this.bookingRules = new BookingRules(this); // Booking rule instance
    this.productCategory = new ProductCategory(this); // product Category instance
    this.multiLocation = new MultiLocation(this); // multi location instance
    this.searchBooking = new SearchBooking(); // instance of Search booking
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    window.APO__selectType = this.APO__selectType.bind(this); // Bind Appointments type
    window.cancelAppointment = this.cancelAppointment.bind(this); // Cancel Appointments
    window.rescheduleAppointment = this.rescheduleAppointment.bind(this); // Reschedule Appointments type
    window.bindSearchApForm = this.bindSearchApForm.bind(this); // Reschedule Appointments type
  }

  bindSearchApForm() {
    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");

    // Set the step to 0
    this.APOData.step = 0;

    // Destructure necessary properties from APOData
    const { step, lang, widgetLanguage } = this.APOData;
    const { searchAPOScreen } = widgetLanguage[lang];

    // Display placeholder loading
    this.commonMethod.updateHandlebarsTemp(
      APO__content,
      this.handlebarsTemplate.placeholderLoading_form(),
      ""
    );

    // Ensure the back button is present if the type is "Service"
    if (!document.querySelector(".APO__back")) {
      this.booking.APO_addBackbtn();
    }

    // update the header title
    this.commonMethod.updateBoxTitle(searchAPOScreen.headerTitle);

    // Prepare the data for the Handlebars template
    const templateData = {
      goBackStep: step,
      lang: searchAPOScreen
    };

    // Render the booked appointment list template with the fetched data
    this.commonMethod.updateHandlebarsTemp(
      APO__content,
      this.handlebarsTemplate.template_searchAppointmentForm(),
      templateData
    );

    // Attach event listeners for the booking form
    this.searchBooking.findBookingFormListeners();
  }

  async APO__AppointmentBookedList() {
    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");
    try {
      // Display placeholder loading
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.placeholderLoading_bookedList(),
        ""
      );

      // Fetch the list of appointments
      // const AppointmentsList = await this.apiHandler.GetAPI_deleteAPI_method(
      //   "Appointments",
      //   "GET"
      // );

      // Filter booking rules to get cancellation and rescheduling rules
      const allowedKeys = [
        "IsAllowOnlineRescheduling",
        "IsAllowOnlineCancellation",
      ];
      const rule_CancelResdul = await this.bookingRules.filterBookingRule(
        allowedKeys
      );

      // Destructure the data and status from the response
      //const { data, status } = AppointmentsList;

      // if (data?.length && status === 200) {

      //   const searchData = data.map((appointment) => ({
      //     ...appointment,
      //     isOldAppointment: moment().isBefore(moment(appointment.startDate)),
      //   }));

      //   console.log(searchData)

      //   this.APOData.AppointmentsList = searchData;

      //   // Render the booked appointment list template with the fetched data
      //   this.commonMethod.updateHandlebarsTemp(
      //     APO__content,
      //     this.handlebarsTemplate.template_bookedAppointmentList(),
      //     {
      //       BookingData: searchData,
      //       rule_CancelResdul,
      //     }
      //   );
      // } else {
      // If no appointments are found, display the appointment type selection box
      await this.booking.APO__TypeBoxHTML();
      //}
    } catch (error) {
      // Update UI with retry option
      console.error("Error occurred while while fetching appointments:", error);
      const message =
        "An error occurred while fetching appointments. Please try again.";
      this.commonMethod.handleErrorWithRetry(
        true,
        APO__content,
        message,
        this.APO__AppointmentBookedList.bind(this)
      );
    }
  }

  async APO__selectType(event, element) {
    if (!!event) {
      event.preventDefault();
      this.APOData.APOtype = element.getAttribute("href");
      //this.booking.APO_addBackbtn();
    }
    
    // Destructure necessary properties from APOData
    const { APOtype, locationList } = this.APOData;

    // Fetch location list if not already available
    const islocationAvailable = locationList ? locationList : await this.multiLocation.fetchAllLocations();

    // Bind the location layout if multiple and Handle the appointment if a single location
    if (islocationAvailable.length > 1) {
      await this.multiLocation.bindLocationLayout(islocationAvailable);
    } else {
      await this.productCategory.APO__AppointmentLayout(this.APOData[APOtype]);
    }
  }

  async cancelAppointment(event) {
    try {
      // Destructure necessary properties from APOData
      const {lang, widgetLanguage} = this.APOData
      const { APOConfirmedScreen, buttonText } = widgetLanguage[lang];

      const { id } = event.currentTarget.dataset;

      // Show loader on the clicked element
      this.commonMethod.setBtnLoader(true, event.currentTarget, [27]);

      // Select the booking content element
      const APO__SearchResult = document.querySelector(".APO__SearchResult");

      // Call the API to cancel the appointment and delete it
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        `Appointments?AppointmentId=${id}`,
        "DELETE"
      );
      // const response = {
      //   "id": "APPLMJ1JDKK",
      //   "success": true,
      //   "message": "Cancelled successfully"
      // }

      // Find the key corresponding to the English message
      const APOConfirmedScreen_en = widgetLanguage["en"].APOConfirmedScreen;
      let messageKey = Object.keys(APOConfirmedScreen_en).find(
        (key) => (APOConfirmedScreen_en[key]).toLowerCase() === (response.data.message).toLowerCase()
      );

      let responseMessage = response.data.message
      // If the key exists and the selected language is different from English, get the translated message
      if (messageKey) {
        responseMessage =  APOConfirmedScreen[messageKey] || response.data.message; // Return translated message or fallback to original
      }

      // Prepare the data for the Handlebars template
      const templateData = {
        data: response.data,
        id,
        responseMessage: responseMessage.toLowerCase(),
        lang: {...APOConfirmedScreen, ...buttonText}
      };

      // Check if the API response is successful
      if (response?.status === 200) {
        // Update the booking wrapper with the response data using the Handlebars template
        this.commonMethod.updateHandlebarsTemp(
          APO__SearchResult,
          this.handlebarsTemplate.cancelBookedAppointment(),
          templateData
        );
      }
      else {
        console.error("Error occurred while cancelling appointment:");
      }
    } catch (error) {
      console.error("Error occurred while cancelling appointment:", error);
    } finally {
      // Hide the loader on the button after API call is complete
      this.commonMethod.setBtnLoader(false, event.currentTarget);
    }
  }

  async rescheduleAppointment(event) {
    const { id } = event.currentTarget.dataset;
    const { APOtype, SearchBookingResult, APO_selectedData, onboardingData, selectedLocation } =
      this.APOData;

    // Show loader on the button
    this.commonMethod.setBtnLoader(true, event.currentTarget, [27]);

    try {
      // Find the appointment data based on ID
      const appointment = SearchBookingResult.find((list) => list.id === id);

      // If onboarding data is not available, fetch it from the API
      if (!onboardingData) {
        await this.productCategory.fetchOnboardingData();
      }

      // Destructure appointment data for easy access
      const {
        staffId,
        staffName,
        businessHoursList,
        serviceId,
        eEventType,
        duration,
        price,
        serviceName,
        currencySymbol,
        color,
        locationId,
        locationName,
      } = appointment;

      // If selectedLocation is not available, then reasign it
      // this.APOData.selectedLocation APP4MDL98BY
      if(!selectedLocation) {
        this.APOData.selectedLocation = {
          id: locationId,
          locationName
        }
      }

      // Update APO_selectedData with the appointment and staff details
      Object.assign(APO_selectedData, {
        appointmentJSON: {
          id: serviceId,
          title: serviceName,
          APOtype: eEventType,
          color,
          price,
          duration,
          currencySymbol,
        },
        staffJSON: {
          id: staffId,
          name: staffName,
          businessHoursList,
        },
      });

      // Update rescheduling flags and data
      // this.APOData = {
      //   ...this.APOData,
      //   reschedule: true,
      //   bookingId: id,
      //   APOtype: eEventType === "Appointment" ? "Service" : eEventType,
      // };
      this.APOData.reschedule = true;
      this.APOData.bookingId = id;
      this.APOData.APOtype =
        eEventType === "Appointment" ? "Service" : eEventType;

      // Proceed to the date and time selection step
      this.booking.APO__chooseDateTime();
    } catch (error) {
      console.error(`Error in rescheduling appointment: ${error.message}`);
    } finally {
      // Hide the loader after processing is complete
      this.commonMethod.setBtnLoader(false, event.currentTarget, [27]);
    }
  }
}

// Export the AppointmentData
export default BusinessData;
