// import validator from "validator";
import GlobalProps from "./GlobalProps";
import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";
import isIn from "validator/lib/isIn";
import isMobilePhone from "validator/lib/isMobilePhone";

class FormValidation { 
    constructor() {
      this.gblProps = new GlobalProps(); // instance of calendar
      this.APOData = this.gblProps.APOData; // appointment Data
    }

    validateField(field, value, validationSchema) {
        // Destructure necessary data from APOData
        const { lang, widgetLanguage } = this.APOData;
        const {validationMessage} =  widgetLanguage[lang];

        let isValid = true;
        let errorMessage = "";
      
        const rules = validationSchema[field];
      
        // Early exit if no validation rules are defined
        if (!rules) {
          return { isValid, errorMessage };
        }
      
        for (const rule of rules) {
          switch (rule.type) {
            case "isRequired":
              if (!isLength(value, { min: 1 })) {
                isValid = false;
                errorMessage = rule.message || validationMessage.required;
              }
              break;
      
            case "isEmail":
              if (!isEmail(value)) {
                isValid = false;
                errorMessage = rule.message || validationMessage.invalidEmail;
              }
              break;
      
            case "isIn":
              if (!isIn(value, rule.options)) {
                isValid = false;
                errorMessage = rule.message || validationMessage.invalidValue;
              }
              break;
      
            case "isMobilePhone":
              if (!isMobilePhone(value, rule.locale)) {
                isValid = false;
                errorMessage = rule.message || validationMessage.invalidPhoneNumber;
              }
              break;
      
            // Extendable case for future validation types
            default:
              break;
          }
      
          // Exit loop early if invalid
          if (!isValid) break;
        }
      
        return { isValid, errorMessage };
      };
      
      toggleSubmitButton(form, validationSchema, submitBtn){
        let isFormValid = true;
      
        form.querySelectorAll("input, select").forEach((inputElement) => {
          // Handle jQuery custom select inputs
          const value = inputElement.classList.contains("jqueryCustomSelect")
            ? $(inputElement).val()
            : inputElement.value;
      
          const { isValid } = this.validateField(
            inputElement.name,
            value,
            validationSchema
          );
      
          // If any field is invalid, disable the form submission and exit early
          if (!isValid) {
            isFormValid = false;
            return; // Exit loop early for performance
          }
        });
      
        // Enable or disable the submit button based on form validity
        submitBtn.disabled = !isFormValid;
      };
      
      showValidationMessages(inputElement, errorMessage) {
        let parentElement = inputElement.parentElement;
        const checkClass = ["give-star-rating", "jqueryCustomSelect"];
      
        if (checkClass.some((cls) => inputElement.classList.contains(cls))) {
          parentElement = parentElement.parentElement;
        }
      
        // Clear previous error messages for this field
        const existingError = parentElement.querySelector(".has-error");
        if (existingError) existingError.remove();
      
        // Only create and append an error message if there's an error
        if (errorMessage) {
          const errorMessageElement = document.createElement("div");
          errorMessageElement.className = "has-error";
          errorMessageElement.style.fontSize = "13px";
          errorMessageElement.textContent = errorMessage;
          parentElement.appendChild(errorMessageElement);
        }
      };
}

export default FormValidation;