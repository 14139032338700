import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import commonMethod from "./Common-methods";
import HandlebarsTemplate from "./Handlebars-template";

class ProductCategory {
  constructor() {
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.commonMethod = new commonMethod(); // instance of common methods
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
  }

  async APO__DataUpdate(data, type) {
    const { userCurrencySymbol } = this.APOData.onboardingData;
    return data.map((item) => ({
      ...item,
      currencySymbol: userCurrencySymbol,
      APOtype: type,
    }));
  }

  // Helper function to fetch and set onboarding data
  async fetchOnboardingData() {
    try {
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        "Onboarding",
        "GET"
      );

      if (!response || !response.data) {
        throw new Error("Invalid API response: No data received.");
      }

      // Extract and set onboarding data
      this.APOData.onboardingData = response.data;

      // Set the business timezone globally
      await this.commonMethod.setTimezoneGlobally(response.data);
    } catch (error) {
      throw new Error(`Failed to fetch onboarding data: ${error.message}`);
    }
  }

  async get__ServiceEventClass(APOtype) {
    try {
      // Destructure necessary properties from APOData
      let { onboardingData, APOtypeList } = this.APOData;
      const matchingType = APOtypeList.find(items => items.typeName === APOtype);

      let data = [];
      // Fetch data if the type is not "Service" and the data is not already loaded
      switch (APOtype) {
        case "Service":
        case "Appointment":
          data = onboardingData.serviceMenusList;
          break;
        case "GlobalEvents":
        case "Class":
          const endPoint= APOtype === "GlobalEvents" ? "Events" : matchingType.displayName;
          const response = await this.apiHandler.GetAPI_deleteAPI_method(endPoint, "GET");
          data = await this.addEventLocationTypeParam(response.data, APOtype);
      }

      // Update and return the data for the given APOtype
      this.APOData[APOtype] = await this.APO__DataUpdate(data, APOtype);
      return this.APOData[APOtype];
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return [];
    }
  }
  
  async addEventLocationTypeParam (data, APOtype) {
    return data.map((item) => ({
      ...item,
      usedSlots: APOtype === "Class" ? item.usedSeats : item.usedSlots,
      displayLocationType:
        item.locationType === "OnlinePhysical"
          ? [
              { location: item.location, locationType: "Online" },
              { location: "Inperson", locationType: "Physical" },
            ]
          : [
              {
                location: item.locationType === "Physical" ? "Inperson" : item.location,
                locationType: item.locationType
              },
            ],
    }));
  };

  async APO__AppointmentLayout(result) {
    // Destructure necessary data from APOData
    let { APOtype, locationList, APOtypeList, selectedLocation, lang, widgetLanguage } = this.APOData;
    const { appointmentListScreen, APOtypeScreen, APORuleMessage, eventLocationType } = widgetLanguage[lang];
    let appointmentData = result.length
      ? result
      : await this.get__ServiceEventClass(APOtype);

    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");

    // update step
    this.APOData.step = (locationList.length > 1) ? 2 : (APOtypeList.length > 1) ? 1 : 0;

    //filter event based on selected location
    if (APOtype === "GlobalEvents") {
      appointmentData = appointmentData
      .filter(item =>
        item.locationType === "OnlinePhysical" || 
        item.locationType === "Online" || 
        (item.locationId === selectedLocation.id && item.locationType === "Physical")
      )
    }

    // Prepare the data for the Handlebars template
    const templateData = {
      goBackStep: this.APOData.step,
      appointmentData,
      APOtype,
      selectedLocation,
      lang: {
        ...appointmentListScreen,
        ...eventLocationType,
        APO_emptyData: `${APOtypeScreen[APOtype]} ${APORuleMessage.APO_emptyData}`,
      }
    };

    // update the header title
    this.commonMethod.updateBoxTitle(appointmentListScreen[APOtype]);
    
    // Check if appointment data is valid before updating the template
    if (templateData.appointmentData) {
      // Render the content using the Handlebars template
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.template_APOLayoutlist(),
        templateData
      );
      
    } else {
      console.error(`No valid appointment data for : ${APOtype}`);

      // Update UI with retry option
      const message = `${appointmentListScreen.retryMessage} ${APOtypeScreen[APOtype]}.`;
      this.commonMethod.handleErrorWithRetry(
        true,
        APO__content,
        {
          lang: {
            retryMessage: message,
            retrybtnText: appointmentListScreen.retrybtnText,
          },
        },
        this.APO__AppointmentLayout.bind(this)
      );
    }
  }
}

// Export the AppointmentData
export default ProductCategory;
