import Handlebars from "handlebars";
import HandlebarsTemplate from "./Handlebars-template";
import moment from "moment";

class HandlebarsHelper {
  constructor() {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
  }

  momentDateTimeFormat(data, format) {
    return moment(new Date(data)).format(format);
  }

  convertUnixTimestamp(timestamp, format) {
    return moment.unix(timestamp).format(format);
  }

  // Function to convert time string to minutes
  convertTimeToMinutes(timeString, split = /h|m/) {
    const [hoursStr, minutesStr] = timeString
      .split(split)
      .map((str) => str.trim());
    const hours = parseInt(hoursStr) || 0;
    const minutes = parseInt(minutesStr) || 0;
    //const totalMinutes = (type == 'Service' || type == 'Appointment')?  hours * 60 + minutes : hoursStr;
    return hoursStr;
  }

  // Helper function to register a partial safely
  async registerPartial(partialName) {

    // Access the template content by the partial name dynamically
    const element = this.handlebarsTemplate[partialName](); 

    // Check if the element exists before registering the partial
    if (element) {
      Handlebars.registerPartial(partialName, element);
    } else {
      console.warn(
        `Template with name "${partialName}" not found, partial "${partialName}" not registered.`
      );
    }
  };

  businessAddressJSON(addressObj) {
    // Check each field and only include non-empty, non-null values
    const addressComponents = [
      addressObj.address || "",
      addressObj.city || "",
      addressObj.state || "",
      addressObj.country || "",
      addressObj.zip || "",
    ].filter(Boolean); // Remove empty or null values
  
    // Join the valid address components into a single string
    const fullAddress = addressComponents.join(", ");
  
    // Replace spaces with '+' to format for Google Maps
    const formattedAddress = fullAddress.replace(/\s/g, "+");
  
    // Construct the Google Maps URL
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
  
    return {
      link: googleMapsLink,
      text: fullAddress,
    };
  };

  registerHelper() {
    const self = this;

    // Register Handlebars partials
    const partials = [
      { name: "APORuleandNoData" },
      { name: "EventslocationType" },
    ]

    // Iterate through the partials and register each one
    partials.forEach((partial) => self.registerPartial(partial.name));

    // Helper to displat dynamic value using when using Partial Helper
    Handlebars.registerHelper('concat', function() {
      return Array.prototype.slice.call(arguments, 0, -1).join('');
    });

    // Helper to apply event color
    Handlebars.registerHelper("eventColor", function (color, options) {
      return new Handlebars.SafeString(`style="color: ${color}"`);
    });

      // Equality check helpers
    Handlebars.registerHelper("if_eq", function (a, b, opts) {
      return a === b ? opts.fn(this) : opts.inverse(this);
    });
    Handlebars.registerHelper("eq", (a, b) => a === b);
    Handlebars.registerHelper("noteq", (a, b) => a !== b);
    Handlebars.registerHelper('not', (value) => !value );
  
    // Or condition helper
    Handlebars.registerHelper("or", function () {
      return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
    });
  
    // And condition helper
    Handlebars.registerHelper("and", function () {
      return Array.prototype.every.call(arguments, Boolean);
    });
    
    Handlebars.registerHelper("if_noteq", function (a, b, opts) {
      return a !== b ? opts.fn(this) : opts.inverse(this);
    });

    Handlebars.registerHelper("ifIn", function (value, list, options) {
      if (!Array.isArray(list)) {
        list = [list];
      }
      // return list.includes(value) ? options.fn(this) : options.inverse(this);
      return list.includes(value) ? options.fn(this) : options.inverse(this);
    });

    // Register an array helper to create arrays in Handlebars
    Handlebars.registerHelper("includeInArray", (...args) => args.slice(0, -1));

    // Day, Date and time formatting helpers
    Handlebars.registerHelper("formatDateTime", (data, format) => moment(new Date(data)).format(format));
    Handlebars.registerHelper("formatTimestamp", (timestamp, format) => !!timestamp ? self.convertUnixTimestamp(timestamp, format) : "");

    // Time conversion helper
    Handlebars.registerHelper("convertTimeToMinutes", function (timeString) {
        const totalMinutes = self.convertTimeToMinutes(timeString);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return hours > 0 ? `${hours}hr ${minutes}min` : `${totalMinutes}min`;
      }
    );

    // first&last letter of string
    Handlebars.registerHelper("returnOnlyZeroindex", function (name, optios) {
      const words = name.trim().split(" ");
      if (words.length === 1) {
        return `${name[0].toUpperCase()}${name[name.length - 1].toUpperCase()}`;
      }
      return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    });

    // check array Length
    Handlebars.registerHelper("ifArrayLength", function (array, opts) {
      return array && array.length > 0 ? opts.fn(this) : opts.inverse(this);
    });

    //safe string
    Handlebars.registerHelper('rawHtmlString', function(context) {
      return new Handlebars.SafeString(context);
    });

    // Register a custom Handlebars helper called "getGoogleMapsLink"
    Handlebars.registerHelper("getGoogleMapsLink", (addressObj) => this.businessAddressJSON(addressObj));

    // Multiply Handlebars helper
    Handlebars.registerHelper("multiply", function(a, b) {
      return parseInt(a) * parseInt(b);
    });
  }
}

export default HandlebarsHelper;
