class APIHandler {
  constructor() {
    this.APIURL = process.env.APIURL;
  }

  async getcookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
  }
  async PostAPI_PutAPI_method(url, method, post_data) {
    try {
    const token = await this.getcookie('tk');

      const response = await fetch(`${this.APIURL}${url}`, {
        withCredentials: true,
        method: method,
        body: JSON.stringify(post_data),
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        return { data, status: response.status };
      } else {
        return { data: null, status: response.status };
      }
    } catch (error) {
      console.warn(err);
      return { status: "error", data: null };
      //throw error; // Re-throwing the error after logging it
    }
  }

  async GetAPI_deleteAPI_method(url, method, target) {
    const token = await this.getcookie('tk');
    try {
      const response = await fetch(`${this.APIURL}${url}`, {
        withCredentials: true,
        method: method,
        headers: {
          Authorization: token || "",
          target: target || "",
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        return { data, status: response.status };
      } else {
        return { data: null, status: response.status };
      }
    } catch (error) {
      console.warn(err);
      return { status: "error", data: null };
      //throw error; // Re-throwing the error after logging it
    }
  }

//   async loadOnboardingData(url, type) {
//     try {
//       return this.GetAPI_deleteAPI_method(url, "GET");
//     } catch (error) {
//       console.log(error);
//     }
//   }
}

export default APIHandler;
