class GlobalProps {
    constructor() {
        if (GlobalProps.instance) {
            return GlobalProps.instance;
        }
        this.APOData = {
			onboardingData: null,
			Service: [],
			GlobalEvents: [],
			Class: [],
			APO_selectedData: {
				appointmentID: null,
				staffID: null,
				date: null,
				timeslot: null,
				appointmentJSON: null,
				staffJSON: null
			},			
			APOtype: null,
			CountriesList: []
		};
        GlobalProps.instance = this;
    }
}
const instance = new GlobalProps();
Object.freeze(instance);
export default GlobalProps;