class HandlebarsTemplate {
    constructor() {

    }

    template_BoxTitle () {
        return `{{bookingBtitle}}`;
    }

    APORuleandNoData() {
        return `<div class="APO-rounded-xl APO-p-6" style="background-color: #f9f9f9;"><span>{{message}}</span></div>`
    }
    
    errorTemplate() {
        return `<div class="flex flex-col items-center APO-gap-4">
            <img class="img-fluid w-52" src="https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/error.svg" alt="{{lang.retrybtnText}}">
            <span class="APO-text-lg text-center" style="font-family: Inter;">{{lang.retryMessage}}</span>
            {{#if showTryAgain}}
            <button type="button" class="tryAgainBtn APO-mt-4 APO-p-2 APO-font-normal w-full APO-rounded-xl APO-border-0">{{lang.retrybtnText}}</button> 
            {{/if}} 
        </div>`
    }

    placeholderLoading_boxedlayout() { 
        return `<div class="APO__boxedlayout flex flex-wrap APO-gap-4">
            <div class="ph-item APO-p-0 bg-transparent APO-p-4 APO-rounded-xl APO__boxed">
                <div class="ph-col-12 APO-p-0 APO-mb-0 APO-gap-4">
                    <div class="ph-avatar w-14 h-14 APO-rounded-xl items-center APO-mx-auto"></div>
                    <div class="ph-row justify-center">
                        <div class="ph-col-4 APO-rounded-xl"></div>
                    </div>
                </div>
            </div>
            <div class="ph-item APO-p-0 bg-transparent APO-p-4 APO-rounded-xl APO__boxed">
                <div class="ph-col-12 APO-p-0 APO-mb-0 APO-gap-4">
                    <div class="ph-avatar w-14 h-14 APO-rounded-xl items-center APO-mx-auto"></div>
                    <div class="ph-row justify-center">
                        <div class="ph-col-4 APO-rounded-xl"></div>
                    </div>
                </div>
            </div>
        </div>`;
    }
    
    placeholderLoading_bookedList_2() { 
        return `<div class="ph-item APO-border-0 APO-p-0 APO-mb-4 bg-transparent APO-p-4 APO-rounded-xl APO__boxed" style="background-color: #fafafa;">
                <div class="ph-col-2 APO-pl-0">
                    <div class="ph-avatar w-14 h-14 APO-rounded-xl" style="min-width: auto;"></div>
                </div>
                <div class="APO-px-0 APO-gap-2">
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-4 APO-rounded-xl"></div>
                        <div class="ph-col-6 empty"></div>
                        <div class="ph-col-2 APO-rounded-xl"></div>
                    </div>
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-2 APO-rounded-xl"></div>
                    </div>
                </div>
                <div class="ph-col-12 APO-px-0 APO-gap-2">
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-8 APO-rounded-xl"></div>
                    </div>
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-6 APO-rounded-xl"></div>
                    </div>
                </div>
            </div>`;
    }

    placeholderLoading_bookedList() { 
        return `<div class="ph-item APO-border-0 APO-p-0 APO-mb-4 bg-transparent APO-p-4 APO-rounded-xl APO__boxed" style="background-color: #fafafa;">
                <div class="ph-col-2 APO-pl-0">
                    <div class="ph-avatar w-14 h-14 APO-rounded-xl" style="min-width: auto;"></div>
                </div>
                <div class="APO-px-0 APO-gap-2">
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-4 APO-rounded-xl"></div>
                        <div class="ph-col-6 empty"></div>
                        <div class="ph-col-2 APO-rounded-xl"></div>
                    </div>
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-2 APO-rounded-xl"></div>
                    </div>
                    <div class="ph-row APO-mt-0">
                        <div class="ph-col-6 APO-rounded-xl"></div>
                    </div>
                </div>
            </div>`;
    }

    placeholderLoading_form() {
        return `<div class="ph-item APO-mt-8 APO-border-0 APO-p-0 bg-transparent">
            <div class="ph-col-12 APO-p-0">
                <div class="ph-row APO-mb-4">
                    <div class="ph-col-12 big APO-rounded-xl"></div>
                    <div class="ph-col-8 APO-rounded-xl"></div>
                </div>
                <div class="ph-row APO-mb-4">
                    <div class="ph-col-12 big APO-rounded-xl"></div>
                    <div class="ph-col-8 APO-rounded-xl"></div>
                </div>
                <div class="ph-row APO-mb-4">
                    <div class="ph-col-12 big APO-rounded-xl"></div>
                    <div class="ph-col-8 APO-rounded-xl"></div>
                </div>
            </div>
        </div>`;
    }

    placeholderLoading_Timeslot() {
        return `<span class="block APO__selecteDaytitle APO-text-base APO-font-medium APO-mt-6  APO-mb-4">{{formatDateTime selectedDate "MMMM DD, YYYY"}}</span>
            <div class="ph-item ph-timeslots APO-border-0 APO-p-0">
                <ul class="grid APO-gap-4 w-full ph-row APO-m-0 APO-p-0" style="grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));">
                    <div class="big APO-rounded-xl" style="height: 39px"></div>
                    <div class="big APO-rounded-xl" style="height: 39px"></div>
                    <div class="big APO-rounded-xl" style="height: 39px"></div>
                    <div class="big APO-rounded-xl" style="height: 39px"></div>
                </ul>
            </div>`;
    }

    template_APOPlugin() {
        return `<div class="APO-booking">
        {{#with buttonOptions}}
        <button type="button" class="booking-toggle APO-text-lg fixed flex justify-center items-center APO-gap-3 btnposition__{{buttonPosition}}">{{text}}</button>
        <div id="APO_bookingBox" class="bookingBox--wrapper fixed shadow-md APO__box-shadow APO-rounded-2xl hide btnposition__{{buttonPosition}}">
            <div class="bookingBox__ flex flex-col"> 
                <div class="APO__screenmask transition_class flex justify-center items-center">
                    <span class="APO__loader APO__spin h-16 w-16"></span>
                </div>
                <div class="booking__Head APO-p-5 APO-gap-2 shadow-lg relative flex justify-between items-center">
                    <span class="bookingBox__title APO-text-lg APO-font-semibold">Choose an appointment</span>
                    <div class="APO-closeSlide APO-rounded-full flex justify-center items-center cursor-pointer w-5 h-5" onclick="APO_opencloseBookingbox(event, this)"><svg class="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg></div>
                </div>
                <div class="APO__content APO-pt-7 APO-px-5 hide-scrollbar overflow-y-auto {{#unless ../IsHideAppointUsBranding}}APO__footerEnable{{/unless}}">Loading...</div>
                {{#unless ../IsHideAppointUsBranding}}
                <div class="APO__footer APO-text-xs">
                    <a href="http://dev.appointusonline.com/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-end APO-gap-1">
                        <span>We schedule with</span>
                        <img class="img-fluid w-5" src="https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/favicon.png">
                        <span class="APO-font-medium">appointusonline</span>
                    </a>
                </div>
                {{/unless}}
            </div> 
        </div>
        <style>
        .booking-toggle {
            background-color: {{backgroundColor}}!important;
            color: {{color}}!important;
            border-radius: {{borderRadius}}!important;
            max-width: {{width}}!important;
            height: {{height}}!important;
        }
        .booking-toggle:hover {
            background-color: {{hoverbackgroundColor}}!important;
            color: {{hovercolor}}!important;
            border
        }
        </style>
        {{/with}}
        </div>`;
    }

    template_NewAPOandSearchScreen () { 
        return `<div class="APO__boxedlayout flex flex-col APO-gap-16 justify-between">
            <div class="flex flex-wrap APO-gap-4">
                <div onclick="bindSearchApForm(event, this)" class="APO__typeList APO__boxed APO__box-shadow APO__hover cursor-pointer APO-rounded-xl APO-p-4 flex items-center flex-col justify-center APO-gap-4 h-32">
                    <svg class="svg-inline--fa fa-clock APO__OptionIcon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="fa-clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#49505f" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                    <span class="__APOtype_title APO-text-base text-center">{{lang.APO_reschedule}}</span>
                </div>
                <div onclick="APO__TypeBoxHTML(event, this)" class="APO__typeList APO__boxed APO__box-shadow APO__hover cursor-pointer APO-rounded-xl APO-p-4 flex items-center flex-col justify-center APO-gap-4 h-32">
                    <svg class="svg-inline--fa fa-calendar-check APO__OptionIcon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#49505f" d="M128 0c13.3 0 24 10.7 24 24l0 40 144 0 0-40c0-13.3 10.7-24 24-24s24 10.7 24 24l0 40 40 0c35.3 0 64 28.7 64 64l0 16 0 48 0 256c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192l0-48 0-16C0 92.7 28.7 64 64 64l40 0 0-40c0-13.3 10.7-24 24-24zM400 192L48 192l0 256c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-256zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <span class="__APOtype_title APO-text-base text-center">{{lang.APO_new}}</span>
                </div>
            </div>
            <img src="https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/widgetHomeBanner.png" class="img-fluid" />
        </div>`;
    }

    template_APOType () { 
        return `<div class="APO__boxedlayout flex flex-wrap APO-gap-4">
            {{#if IsBookAppointmentVisible}}
                {{#ifArrayLength APOtypeList}}
                    {{#each APOtypeList}}
                        <a href="{{typeName}}" onclick="APO__selectType(event, this)" class="APO__typeList APO__boxed APO__box-shadow APO__hover cursor-pointer APO-rounded-xl APO-p-4 flex items-center flex-col justify-center text-center APO-gap-4 h-32">
                            {{{icon}}}
                            <span class="__APOtype_title APO-text-base">{{lookup ../lang typeName}}</span>
                        </a>
                    {{/each}}
                {{else}}
                    {{> APORuleandNoData message=lang.emptyData}}
                {{/ifArrayLength}}
            {{else}}
                {{> APORuleandNoData message=lang.OnlineBookingUnavailable}}
            {{/if}}
        </div>`;
    }

    EventslocationType () {
        return `{{#each EventslocType}}
                {{#if (or (eq ../eventlocId ../selectedlocId) @first)}}
                <div class="flex items-center APO-gap-1 APO-p-1 APO-rounded-md" style="background-color: #E9F3FF;">
                    {{#if (eq locationType "Physical")}}
                        <svg class="svg-inline--fa fa-user-group" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-group" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
                        <span>{{../lang.eventInperson}}</span>
                    {{else}}
                        <img src="https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/{{location}}.svg" class="img-fluid w-5" alt="">
                        <span>
                            {{#if (eq location "GoogleMeet")}}Google Meet{{else}}{{location}}{{/if}}
                        </span>
                    {{/if}}
                </div>
                {{/if}}
            {{/each}}`
    }

    template_APOLocationList () {
        return `<div class="APO__LocationList flex flex-col APO-gap-5">
        {{#each locationList}}
            <div class="relative APO__hover APO__box-shadow APO-rounded-xl APO-p-4">
                <div data-id="{{id}}" onclick="APO__chooseLocation(event, this)" class="cursor-pointer flex APO-gap-3">
                    <div class="flex items-center justify-center w-16 h-16 APO-text-sm APO-font-medium APO-rounded-xl bg-slate-100 APO-mr-1" style="font-family: inherit;min-width: 64px;">
                        {{returnOnlyZeroindex locationName}}
                    </div>
                    <div class="flex flex-col APO-gap-2">
                        <span class="APO__title APO-text-lg APO-font-medium APO-pr-5">{{locationName}}</span>
                        {{#with (getGoogleMapsLink this) as |addressData|}}
                            <div class="flex flex-wrap APO-gap-1">
                                {{addressData.text}}
                            </div>
                        {{/with}}
                    </div>
                </div>
                <div class="locationDirectionIcon">
                    {{#with (getGoogleMapsLink this) as |addressData|}}
                        <a href="{{addressData.link}}" class="flex items-center APO-gap-1 APO-px-1 APO-rounded-md" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#252525" d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8l0 56-64 0c-35.3 0-64 28.7-64 64l0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48c0-8.8 7.2-16 16-16l64 0 0 56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z"/></svg>
                        </a>
                    {{/with}}
                </div>
            </div>
        {{/each}}
        </div>`;
    }

    template_APOLayoutlist () {
        return `<div class="booking-content flex flex-col APO-gap-4">
        {{#ifArrayLength appointmentData}}
            {{#each appointmentData}}
                <a {{#if (or (and (includeInArray APOtype "GlobalEvents" "Class") usedSlots) (eq APOtype "Service"))}} href="{{id}}" onclick="APO__chooseAppointment(event, this)" {{else}} href="javascript:void(0)"{{/if}} class="service-wrapper APO__box-shadow APO__hover cursor-pointer APO-rounded-xl flex APO-p-4 APO-gap-4 relative">
                    <div class="flex items-center justify-center w-16 h-16 APO-text-sm APO-font-medium APO-rounded-xl overflow-hidden bg-slate-100" style="font-family: inherit;min-width: 64px;">
                    {{#if image}}
                        <img class="img-fluid object-cover h-full" src="{{image}}" alt="{{title}}">
                        {{else}}
                            {{returnOnlyZeroindex title}}
                        {{/if}}
                    </div>
                    <div class="flex flex-col APO-gap-2 w-full">
                        <span class="APO__title APO-text-lg APO-font-medium">{{title}}</span>
                        <div class="APO__mutetext silver__font flex flex-col w-full APO-gap-1">
                            {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                                <div class="flex items-center APO-gap-2">
                                    {{> EventslocationType EventslocType=displayLocationType eventlocId=locationId selectedlocId=../selectedLocation.id lang=../lang }}
                                </div>
                                <div class="flex w-full APO-gap-1">
                                    <span class="event-month">{{formatDateTime startDate "MMM DD, YYYY"}}</span>
                                    {{#if (or (eq APOtype "Class") (and (eq APOtype "GlobalEvents") (not allDay)))}}
                                        <span class="event-date">@</span>
                                        <span class="event-time">{{formatDateTime startDate "HH:mm"}} - {{formatDateTime endDate "HH:mm"}}</span>
                                    {{/if}}
                                </div>
                            {{else}}
                                <div class="flex w-full APO-gap-1">
                                    <span>{{../lang.timeDuration}}</span>
                                    <span>{{convertTimeToMinutes duration APOtype}}</span>
                                </div>
                            {{/ifIn}}
                        </div>
                        <div class="flex justify-between items-center gap-3">
                            {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                                <span>{{usedSlots}} {{../lang.slotsLeft}}</span>
                            {{/ifIn}}
                            {{#if (noteq price "0")}}
                                <div class="flex APO-gap-2">
                                    <span class="gray-badge">{{currencySymbol}} {{price}}</span>
                                </div>
                            {{/if}}
                        </div>
                    </div>
                </a>
            {{/each}}
            {{else}}
            {{> APORuleandNoData message=lang.APO_emptyData}}
        {{/ifArrayLength}}
        </div>`;
    }

    template_APOStaff () { 
        return `
        <div class="__selectStaff APO__boxedlayout flex flex-wrap APO-gap-4">
        {{#ifArrayLength staffList}}
            {{#if IsAssignAnyTeamMember}}
                <a href="{{assignAnyStaff.id}}" onclick="APO__chooseStaff(event, this)" class="APO__boxed APO__hover APO__box-shadow cursor-pointer APO-rounded-xl APO-p-4 flex items-center flex-col justify-between APO-gap-3">
                    <div class="APO_imgplaceholder w-10 h-10 APO-text-base APO-font-medium APO-rounded-xl flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-32-32 0c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96l32 0 0-32c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-32-32 0c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8l32 0 0-32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"/></svg>
                    </div>
                    <span class="__Staffname APO-text-sm APO-font-semibold text-center APO-px-1">{{lang.title}}</span>
                    <hr />
                    <span class="__StaffRole APO-text-sm APO-font-light silver__font text-center">{{lang.subTitle}}</span>
                </a>
            {{/if}}
            {{#each staffList}}
            {{#if isMemberCalenderShow}}
                <a href="{{id}}" onclick="APO__chooseStaff(event, this)" class="APO__boxed APO__hover APO__box-shadow cursor-pointer APO-rounded-xl APO-p-4 flex items-center flex-col justify-between APO-gap-3">
                    <div class="APO_imgplaceholder w-16 h-16 APO-text-base APO-font-medium APO-rounded-xl overflow-hidden flex justify-center items-center bg-slate-100">
                        {{#if image}}
                            <img class="img-fluid object-cover" src="{{image}}" alt="{{title}}">
                        {{else}}
                            {{returnOnlyZeroindex name}}
                        {{/if}}
                    </div>
                    <span class="__Staffname APO-text-sm APO-font-semibold text-center">{{name}}</span>
                    <hr />
                    <span class="__StaffRole APO-text-sm APO-font-light silver__font">{{jobTitle}}</span>
                </a>
            {{/if}}
            {{/each}}
            {{else}}
                {{> APORuleandNoData message=lang.staffUnavailableForLocation}}
        {{/ifArrayLength}}
        </div>`;
    }

    template_Calendar () {
        return `
        <div class="booking-content flex flex-col APO-gap-5 transition_class">
            {{#if filteredVisibleStaff}}
                <div class="APO__calendar">
                    <div id="APO__datepicker" class="APO__datepicker"></div>
                    <div class="APO__BookingSlot"></div>
                </div>
                {{else}}
                {{> APORuleandNoData message=lang.staffUnavailableForLocation}}
            {{/if}}
        </div>`;
    }

    template_BookingStols() {
        return `<span class="block APO__selecteDaytitle APO-text-base APO-font-medium APO-mt-6  APO-mb-4">{{formatDateTime selectedDate "MMMM DD, YYYY"}}</span>
            {{#ifArrayLength Bookingslots}}
                <ul class="grid APO-gap-3 w-full" style="grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));">
                {{#each Bookingslots}}
                    {{#if break}}
                        <li class="APO__Timeslots APO__box-shadow APO-text-sm text-center APO-rounded-xl APO__hover breakHour" data-slot="{{timestamp}}" title="Break Hours">{{slot}}</li>
                    {{else}}
                        <li class="APO__Timeslots APO-text-sm text-center APO-rounded-xl APO__hover" data-slot="{{timestamp}}" onclick="APO__Bookingslots(event, this)">{{slot}}</li>
                    {{/if}}
                {{/each}}
                </ul>
                {{else}}
                    {{> APORuleandNoData message=lang.slotsUnavailable}}
            {{/ifArrayLength}}`;
    }

    template_BookingOrderDetail() {
        return ` <div class="APO__orderDetail APO-px-5 APO-py-3 relative">
            <div class="APO__orderHead flex justify-between">
                <span class="bookingBox__title APO-text-lg APO-font-semibold APO-mb-3">{{lang.title}}</span>
                <div class="APO__roundedicon APO-rounded-full flex justify-center items-center cursor-pointer w-5 h-5 bg-slate-100" onclick="APO__orderDminimize(event, this)">
                    <svg class="svg-inline--fa fa-angle-down" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                </div>
            </div>

            {{#with appointmentJSON}}
                <div class="APO__orderDContent flex APO-gap-3 relative">
                    <div class="APO_imgplaceholder w-16 h-16 APO-text-base APO-font-medium APO-rounded-xl overflow-hidden flex justify-center items-center bg-slate-100" style="min-width: 64px;">
                        {{#if image}}
                            <img class="img-fluid object-cover" src="{{image}}" alt="{{title}}">
                        {{else}}
                        {{ returnOnlyZeroindex title }}
                        {{/if}}
                    </div>
                    <div class="APO__orderData w-full flex flex-col APO-gap-1">
                         <div class="flex justify-between w-full APO-gap-3">
                            <div class="flex flex-col gap-1">
                                <span class="APO__title APO-text-lg APO-font-medium">{{title}}</span>
                                {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                                    <div class="flex items-center APO-gap-2">
                                        {{> EventslocationType EventslocType=displayLocationType eventlocId=locationId selectedlocId=../selectedLocation.id lang=../lang }}
                                    </div>              
                                {{else}}
                                    <span class="APO__mutetext silver__font APO-font-medium">{{../staffJSON.name}}</span>
                                    <span class="flex"><span class="gray-badge silver__font APO-font-medium">{{../selectedLocation.locationName}}</span></span>
                                {{/ifIn}}
                            </div>
                            {{#if (noteq price "0")}}
                                <div class="APO__orderDright flex">
                                    <span class="APO__title APO-text-lg APO-font-medium" style="margin-right:3px">{{ currencySymbol }}</span>
                                    <span class="APO__title APO-text-lg APO-font-medium APO-totalPrice">{{ price }}</span>
                                </div>
                            {{/if}}
                        </div>
                        {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                            <div class="APO__mutetext silver__font flex APO-gap-1">
                                <span class="event-month">{{formatDateTime startDate "MMM DD, YYYY"}}</span>
                                {{#if (or (eq APOtype "Class") (and (eq APOtype "GlobalEvents") (not allDay)))}}
                                    <span class="event-date">@</span>
                                    <span class="event-time">{{formatDateTime startDate "HH:mm"}} - {{formatDateTime endDate "HH:mm"}}</span>
                                {{/if}}
                            </div>
                            <span class="APO__mutetext silver__font">{{usedSlots}} {{../lang.slotsLeft}}</span>
                        {{else}}
                            <div class="APO__mutetext silver__font flex justify-between w-full APO-gap-1">
                                <span>{{formatDateTime ../date "MMM DD, YYYY"}} at {{formatTimestamp ../timeslot "HH:mm"}}</span>
                                <span>{{convertTimeToMinutes duration}}</span>
                            </div>
                        {{/ifIn}}
                    </div>
                </div>            
                <div class="APO__filldetails">
                    <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal APO-mt-3 APO-p-2 w-full APO-rounded-xl APO-border-0" {{#if (eq APOtype "Service")}}{{#unless ../timeslot}} disabled  {{/unless}} {{/if}} onclick="APO__orderdone(event, this)">{{../lang.buttonText}}</button>            
                </div>
            {{/with}}
        </div>`;
    }

    template_searchAppointmentForm() { 
        return `<div class="flex flex-col APO-gap-4"><span class="bookingBox__title APO-text-lg APO-font-semibold">{{lang.formTitle}}</span>
            <form onsubmit="searchAppointmentFrom(event)" id="bookedApo_searchFrom" class="flex flex-col APO-gap-6">
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fieldLabel}}*</label>
                    <input class="input-field APO-rounded-lg" type="text" name="bookingInfo" placeholder="{{lang.fieldPlaceholder}}" required>
                </div>
                <div class="flex flex-col APO-gap-2">
                    <button type="submit" class="APO__bookingBtn APO-text-base APO-font-normal APO-px-2 APO-py-3 w-full APO-rounded-lg APO-border-0" disabled>{{lang.buttonText}}</button>
                </div>
            </form>
            <div class="APO__SearchResult"></div>
            </div>`;
    }

    template_fillContactDetail() { 
        return `<span class="block bookingBox__title APO-text-lg APO-font-semibold APO-mb-4 APO-mt-8">{{lang.formTitle}}</span>
            <form id="bookingContactForm" class="flex flex-col APO-gap-6">
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.Name.label}}*</label>
                    <input class="input-field APO-rounded-xl" type="text" name="name" placeholder="{{lang.fields.Name.placeholder}}">
                </div>
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.Email.label}}*</label>
                    <input class="input-field APO-rounded-xl" type="email" name="email" placeholder="{{lang.fields.Email.placeholder}}">
                </div>
                {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.BookSlots.label}}*</label>
                    <div class="flex quantityField-wapper">
                        <button type="button" class="btn-sub flex items-center justify-center" onclick="updateSlotsQuantityCount(event)">
                            <svg class="svg-inline--fa fa-minus" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
                        </button>
                        <input type="number" name="SlotsQuantity" value="{{APO_selectedData.bookSlotQuantity}}" maxlength="{{APO_selectedData.allowMaxEventSlots}}" readonly class="input-field text-center" style="width: 100px !important; padding: 0px 3px !important;" />
                        <button type="button" class="btn-plus flex items-center justify-center" onclick="updateSlotsQuantityCount(event)">
                            <svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>
                        </button>
                    </div>
                </div>
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.AddGuests.label}}</label>
                    <span class="text-mute" style="font-size: 12px;line-height: normal;color: #9f9f9f;margin-top: -9px;">{{lang.fields.AddGuests.infoText}}</span>
                    <div class="APO-guestsEmail-wrapper input-field flex flex-wrap items-start APO-gap-2 APO-rounded-xl" style="height: 100%;min-height: auto;font-size: 14px;border-color: #D9D9D9;border-style: solid;border-width: 0px 0px 1px 0px;padding: 6px 12px;">
                        <input autocomplete="one-time-code" readonly="" onfocus="this.readOnly = false" type="email" name="inviteGuest" placeholder="{{lang.fields.AddGuests.placeholder}}" class="input-field flex-grow" onblur="handleGuestEmailBlur(event)" onkeyup="handleGuestEmailKeyUp(event, this)">
                    </div>
                </div>
                {{/ifIn}}
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.Phone.label}}*</label>
                    <div class="flex inputGroup">
                        <div class="dropdown-field APO-rounded-e-none APO-border-r-0 h-12 APO-p-0 inputstyle">
                            <select class="custom-select__dropdown jqueryCustomSelect DropwithSearch" name="country_code">
                                {{#each CountriesList}}
                                    <option value="{{phone_code}}" {{#if (eq name ../UserCountries)}}selected{{/if}}>+{{phone_code}}</option>
                                {{/each}}
                            </select>
                        </div>
                        <div class="flex flex-col APO-gap-2 w-full">
                            <input class="input-field APO-rounded-xl APO-rounded-s-none" type="number" name="phoneNumber" placeholder="{{lang.fields.Phone.placeholder}}">
                        </div>
                    </div>
                </div>
                <div class="flex flex-col APO-gap-2">
                    <label class="property-label static">{{lang.fields.Country.label}}*</label>
                    <div class="dropdown-field">
                        <select class="custom-select__dropdown jqueryCustomSelect DropwithSearch" name="country">
                            <option value="select">{{lang.fields.Country.placeholder}}</option>
                            {{#each CountriesList}}
                                <option value="{{name}}" {{#if (eq name ../UserCountries)}}selected{{/if}}>{{name}}</option>
                            {{/each}}
                        </select>
                    </div>
                </div>
                <button type="button" onclick="submitBookingContactForm(event)" id="bookingContactFormBtn" class="APO__bookingBtn APO-text-base APO-font-normal APO-mt-4 APO-p-2 w-full APO-rounded-xl APO-border-0" disabled>{{lang.buttonText}}</button>
            </form>`;
    }

    template_appointmentConfirm () { 
        return `<div class="APO__Confirmwrapper APO-px-5 APO-pt-3 APO-pb-8 transition_class flex flex-col APO-gap-4">
            <div class="APO__orderHead flex justify-between items-center APO-gap-2">
                <span class="APO__loader h-8 w-8 flex items-center">
                <svg class="svg-inline--fa fa-check APO-mx-auto" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                </span>
                <span class="bookingBox__title APO-text-lg APO-font-semibold">{{lang.headerTitle}}</span>
                <div class="APO-closeSlide APO-rounded-full flex justify-center items-center cursor-pointer w-5 h-5" onclick="APO_opencloseBookingbox(event, this)">
                    <svg class="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                </div>
            </div>
            <div class="APO__confirmContent APO-p-5 APO-rounded-xl flex flex-col APO-gap-3">
                {{#with appointmentJSON}}
                    <div class="flex APO-gap-3 relative">
                        <div class="APO_imgplaceholder w-16 h-16 APO-text-base APO-font-medium APO-rounded-xl overflow-hidden flex justify-center items-center bg-slate-100" style="min-width: 64px;">
                            {{#if image}}
                                <img class="img-fluid object-cover" src="{{image}}" alt="{{title}}">
                            {{else}}
                                {{ returnOnlyZeroindex title }}
                            {{/if}}
                        </div>
                        <div class="w-full flex flex-col APO-gap-1">
                            {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                                <span class="APO__title APO-text-lg APO-font-medium">{{title}}</span>
                                <div class="flex items-center APO-gap-2">
                                    {{> EventslocationType EventslocType=displayLocationType eventlocId=locationId selectedlocId=../selectedLocation.id lang=../lang }}
                                </div>
                            {{else}}
                                <div class="flex justify-between w-full APO-gap-3">
                                    <div class="flex flex-col">
                                        <span class="APO__title APO-text-lg APO-font-medium">{{title}}</span>
                                        <span class="APO__mutetext silver__font APO-font-medium">{{../staffJSON.name}}</span>
                                    </div>
                                    {{#if (noteq price "0")}}
                                    <div class="APO__orderDright flex">
                                        <span class="APO__title APO-text-lg APO-font-medium" style="margin-right: 3px;">{{ currencySymbol }}</span>
                                        <span class="APO__title APO-text-lg APO-font-medium">{{ price }}</span>
                                    </div>
                                    {{/if}}
                                </div>
                            {{/ifIn}}
                        </div>
                    </div>
                    <div class="flex flex-col APO-gap-2">
                        {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.date}}</span>
                                <span class="APO__mutetext">{{formatDateTime startDate "MMM DD, YYYY"}}</span>
                            </div>
                            {{#if (or (eq APOtype "Class") (and (eq APOtype "GlobalEvents") (not allDay)))}}
                                <div class="flex justify-between">
                                    <span class="APO__mutetext">{{../lang.from}}</span>
                                    <span class="APO__mutetext">{{formatDateTime startDate "h:mm A"}}</span>
                                </div>
                                <div class="flex justify-between">
                                    <span class="APO__mutetext">{{../lang.to}}</span>
                                    <span class="APO__mutetext">{{formatDateTime endDate "h:mm A"}}</span>
                                </div>
                            {{/unless}}
                        {{else}}
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.bookingId}}</span>
                                <span class="APO__mutetext">{{../bookingStatus.id}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.location}}</span>
                                <span class="APO__mutetext">{{../selectedLocation.locationName}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.date}}</span>
                                <span class="APO__mutetext">{{formatTimestamp ../timeslot "MMM DD, YYYY"}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.timing}}</span>
                                <span class="APO__mutetext">{{formatTimestamp ../timeslot "h:mm A"}}</span>
                            </div>
                            <div class="flex justify-between">
                                <span class="APO__mutetext">{{../lang.duration}}</span> 
                                <span class="APO__mutetext">{{convertTimeToMinutes duration}}</span> 
                            </div>
                        {{/ifIn}}
                    </div>
                    {{#if (noteq price "0")}}
                        <div class="flex flex-col">
                            <div class="flex justify-between">
                                <span class="bookingBox__title APO-text-lg">{{../lang.totalAmount}}</span>
                                <span class="flex">
                                    <span class="APO__title APO-text-lg APO-font-medium" style="margin-right: 3px">{{currencySymbol}}</span>
                                    {{#ifIn APOtype (includeInArray "GlobalEvents" "Class")}}
                                        <span class="APO__title APO-text-lg APO-font-medium">{{ multiply price ../bookSlotQuantity}}</span>
                                    {{else}}
                                        <span class="APO__title APO-text-lg APO-font-medium">{{ price}}</span>
                                    {{/ifIn}}
                                </span>
                            </div>
                            <span class="silver__font" style="font-size: 10px;">{{../lang.totalAmountInfo}}</span>
                        </div>
                    {{/if}}
                {{/with}}
            </div>
            <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal APO-mt-2 APO-px-2 APO-py-3 w-full APO-rounded-xl APO-border-0" onclick="newAPOandSearchScreen(event, this)">{{#if IsBookAppointmentButton.isSelected}}{{lang.bookAnother}}{{else}}{{lang.backToHome}}{{/if}}</button>
        </div>`;
    }

    template_bookedAppointmentList () { 
        return `<div class="APO__AllbooikgList transition_class flex flex-col APO-gap-4">
        {{#ifArrayLength BookingData}}
            {{#each BookingData }}
                <div class="APO__confirmContent APO-p-5 APO-rounded-xl flex flex-col APO-gap-3">
                    <div class="flex APO-gap-3 relative">
                        <div class="APO_imgplaceholder w-16 h-16 APO-text-base APO-font-medium APO-rounded-xl overflow-hidden flex justify-center items-center bg-slate-100" style="min-width: 64px;">
                            {{#if image}}
                                <img class="img-fluid object-cover" src="{{image}}" alt="{{serviceName}}">
                            {{else}}
                                {{#if serviceName}}
                                    {{ returnOnlyZeroindex serviceName }}
                                {{else}}
                                    <svg class="svg-inline--fa fa-images APO-text-2xl text-gray-300" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="images" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z"></path></svg>
                                {{/if}}
                            {{/if}}
                        </div>
                        <div class="w-full flex flex-col APO-gap-1">
                            <div class="flex justify-between w-full APO-gap-3">
                                <div class="flex flex-col">
                                    <span class="APO__title APO-text-lg APO-font-medium">{{serviceName}}</span>
                                    <span class="APO__mutetext silver__font APO-font-medium">{{staffName}}</span>
                                </div>
                                {{#if (noteq price "0")}}
                                <div class="APO__orderDright flex">
                                    <span class="APO__title APO-text-lg APO-font-medium" style="margin-right: 3px;">{{ currencySymbol }}</span>
                                    <span class="APO__title APO-text-lg APO-font-medium">{{ price }}</span>
                                </div>
                                {{/if}}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col APO-gap-2">
                        <div class="flex justify-between">
                            <span class="APO__mutetext">{{../lang.bookingId}}</span>
                            <span class="APO__mutetext">{{bookingId}}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="APO__mutetext">{{../lang.location}}</span>
                            <span class="APO__mutetext">{{locationName}}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="APO__mutetext">{{../lang.date}}</span>
                            <span class="APO__mutetext">{{formatDateTime startDate "MMM DD, YYYY"}}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="APO__mutetext">{{../lang.timing}}</span>
                            <span class="APO__mutetext">{{formatDateTime startDate "h:mm A"}} - {{formatDateTime endDate "h:mm A"}}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="APO__mutetext">{{../lang.duration}}</span>
                            <span class="APO__mutetext">{{convertTimeToMinutes duration}}</span>
                        </div>
                    </div>
                    {{#if (noteq price "0")}}
                        <div class="flex flex-col">
                            <div class="flex justify-between">
                                <span class="bookingBox__title APO-text-lg APO-font-semibold">{{../lang.totalAmount}}</span>
                                <div class="flex">
                                <span class="APO__title APO-text-lg APO-font-medium" style="margin-right: 3px">{{ currencySymbol }}</span>
                                <span class="APO__title APO-text-lg APO-font-medium">{{ price }}</span>
                                </div>
                            </div>
                            <span class="silver__font" style="font-size: 10px;">{{../lang.totalAmountInfo}}</span>
                        </div>
                    {{/if}}
                    {{#ifArrayLength ../rule_CancelResdul}}
                        {{#if isOldAppointment}}
                        <div class="flex APO-gap-6">
                            {{#each ../rule_CancelResdul}}
                                {{#if isSelected}}
                                    {{#if (eq keyName "IsAllowOnlineRescheduling")}}
                                        <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal APO-font-normal APO-p-2 w-full APO-rounded-lg APO-border-0" data-id="{{../id}}" onclick="rescheduleAppointment(event)" title="Reschedule">{{../../lang.rescheduleBtn}}</button>
                                    {{/if}}
                                    {{#if (eq keyName "IsAllowOnlineCancellation")}}
                                        <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal APO-font-normal APO-p-2 w-full APO-rounded-lg APO-border-0" data-id="{{../id}}" onclick="cancelAppointment(event)" title="Cancel" style="background-color: transparent!important;border: 1px solid #3C65F5!important;color: #3C65F5!important;">{{../../lang.cancelBtn}}</button>
                                {{/if}}
                               {{/if}}
                            {{/each}}
                        </div>
                        {{/if}}
                    {{/ifArrayLength}}
                </div>
            {{/each}}
            {{else}}
                <div class="flex flex-col APO-rounded-xl APO-p-6 APO-gap-3" style="background-color: #f3ffc1;">
                    <span class="APO-font-medium" style="font-size: 15px;">{{StatusMessage}}</span>
                    <span>{{lang.bookingNotFoundText}}</span>
                </div>
            {{/ifArrayLength}}
            <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal addnewAPO flex items-center justify-center APO-mt-2 APO-p-2 w-full APO-border-0" onclick="APO__TypeBoxHTML(event, this)" title="{{lang.bookNow}}">
                <svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
            </button>
        </div>`;
    }

    cancelBookedAppointment() {
        return `<div class="flex flex-col APO-rounded-xl APO-p-6 APO-gap-3" style="background-color: #f9f9f9;">
            <span class="APO-text-sm APO-font-medium">{{lang.bookingCancelled}} <span style="color: #1e75ff;">{{data.id}}</span> {{responseMessage}}.</span>
            {{#if data.success}}
                <button type="button" class="APO__bookingBtn APO-text-base APO-font-normal APO-mt-4 APO-p-2 w-full APO-rounded-xl APO-border-0" onclick="APO__TypeBoxHTML(event)">{{lang.booknewAPOBtn}}</button>
            {{/if}}
        </div>`
    }

    template_StripeCheckout () {
        return `<div class="widget-wrapper WidthStyle widgetstyle w-full flex flex-col APO-gap-4">
            <form id="stripe-checkout-form" class="flex flex-col APO-gap-4">
                <div class="checkout-wrapper flex-col flex flex-wrap" style="border: 1px solid #e5e6eb;border-radius: 12px;">
                    <div class="flex flex-col APO-gap-4 APO-p-4 bindpaymentElement" style="border-bottom: 1px solid #e5e6eb;">
                        <div class="flex APO-gap-3 items-center">
                            <div class="custom-checkRadio radioStyle relative flex h-5 w-5">
                                <input type="radio" name="pay-with_{{paymentModeName}}" role="radio" value="pay_with_card" checked onchange="handlePaymentMethodChange(event)" />
                                <span class="checkmark-slider flex justify-center items-center">
                                    <span class="radioDot">&nbsp;</span>
                                </span>
                            </div>
                            <label class="property-label static APO-m-0">Pay with card</label>
                        </div>
                        <div class="thirdpartypaymentmethod">
                            <div id="payment-element"></div>
                        </div>
                    </div>
                    <div class="flex APO-gap-3 APO-p-4 items-center">
                        <div class="custom-checkRadio radioStyle relative flex h-5 w-5">
                            <input type="radio" name="pay-with_{{paymentModeName}}" role="radio" value="skip_payment" onchange="handlePaymentMethodChange(event)" />
                            <span class="checkmark-slider flex justify-center items-center">
                                <span class="radioDot">&nbsp;</span>
                            </span>
                        </div>
                        <label class="property-label static APO-m-0">Pay in person</label>
                    </div>
                </div>
                <button id="submit" class="APO__bookingBtn APO-text-base APO-font-normal btn-APOcheckout APO-mt-4 APO-p-2 w-full APO-rounded-xl APO-border-0">Pay & confirm</button>
            </form>
            <div id="error-message" class="has-error"></div>
            <div id="payment-message"></div>
        </div>`;
    }


}

export default HandlebarsTemplate;