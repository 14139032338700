import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";

class BookingRules {
    constructor() {
        this.apiHandler = new APIHandler(); // instance of APIHandler
        this.gblProps = new GlobalProps(); // instance of calendar
        this.APOData = this.gblProps.APOData; // appointment Data
    }

    async fetchBookingRules() {
        try {
          const response = await this.apiHandler.GetAPI_deleteAPI_method("BookingRules", "GET");
          this.APOData.Bookingrules = response.data;
        } catch (error) {
          console.error("Error fetching booking rules:", error);
        }
      };
      
      async filterBookingRule(allowedKeys) {
        const { bookingRulesLists } = this.APOData.Bookingrules;
      
        if (allowedKeys.length > 1) {
          return bookingRulesLists.filter((rule) =>
            allowedKeys.includes(rule.keyName)
          );
        } else {
          return bookingRulesLists.find((rule) => rule.keyName === allowedKeys[0]);
        }
      };

}
export default BookingRules