import APIHandler from "./Api";
import commonMethod from "./Common-methods";
import BookingForm from "./BookingForm";
import HandlebarsTemplate from "./Handlebars-template";
import HandlebarsHelper from "./Handlebars-helper";
import GlobalProps from "./GlobalProps";

class OrderDetails {
  constructor() {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars template
    this.handlebarsHelper = new HandlebarsHelper(); // instance of Handlebars template
    this.commonMethod = new commonMethod(); // instance of common method
    this.bookingForm = new BookingForm(); // instance of form Validation
    this.gblProps = new GlobalProps(); // instance of gobal props class
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
    window.APO__orderDminimize = this.APO__orderDminimize.bind(this); // Bind method class instance
    window.APO__orderdone = this.APO__orderdone.bind(this); // Bind method class instance
  }

  updateBookingOrderDetail(element, isform) {
    // Destructure necessary data from APOData
    const { APO_selectedData, selectedLocation, lang, widgetLanguage } = this.APOData;
    const {orderInfoScreen, eventLocationType} =  widgetLanguage[lang];

    // Prepare the data for the Handlebars template
    const templateData = {
      ...APO_selectedData,
      selectedLocation,
      lang: {...orderInfoScreen, ...eventLocationType}
    };

    this.commonMethod.updateHandlebarsTemp(
      element,
      this.handlebarsTemplate.template_BookingOrderDetail(),
      templateData
    );
  }

  APO_orderdetailbox() {
    let APO__insertOrderD = document.querySelector(".APO__insertOrderD"); // order detail insert content element
    if (!APO__insertOrderD) {
      const APO__content = document.querySelector(".APO__content");
      APO__content.insertAdjacentHTML(
        "beforeend",
        `<div class="APO__insertOrderD transition_class"></div>`
      );
      APO__insertOrderD = document.querySelector(".APO__insertOrderD"); // reassign after creation
    }

    this.updateBookingOrderDetail(APO__insertOrderD, false);

    setTimeout(() => {
      if (!APO__insertOrderD.classList.contains("orderDminimized")) {
        APO__insertOrderD.style.bottom = "27px";
      }
      this.commonMethod.APO__contentHeight();
    }, 100); // 200 milliseconds equals 0.5 seconds
  }

  APO__orderDminimize(event, element) {
    const APO__insertOrderD = document.querySelector(".APO__insertOrderD"); // order detail insert content element
    const APO__filldetails = document.querySelector(".APO__filldetails"); // fill contact detail content element
    const orderDContent = document.querySelector(
      ".APO__orderDContent"
    ).clientHeight;
    const orderHead_H = document.querySelector(".APO__orderHead").clientHeight;

    APO__insertOrderD.classList.remove("fillcontactDetail", "hide-scrollbar");
    const is_minimized = APO__insertOrderD.classList.toggle("orderDminimized");
    APO__insertOrderD.style.top = "auto";
    APO__insertOrderD.style.bottom = `${
      is_minimized ? `-${orderDContent + orderHead_H + 2}px` : "27px"
    }`;

    if (!!APO__filldetails) {
      this.updateBookingOrderDetail(APO__insertOrderD, false);
    }
    setTimeout(() => {
      this.commonMethod.APO__contentHeight();
    }, 500); // 500 milliseconds equals 0.5 seconds
  }

  async fetchCustomerList() {
    const customerListResponse = await this.apiHandler.GetAPI_deleteAPI_method("Lists", "GET");
    const readOnlyCustomer = customerListResponse.data.find(
      (item) => item.isReadOnly
    );
    if (readOnlyCustomer) {
      this.APOData.AllcustomerID = readOnlyCustomer.id;
    }
  };

  async APO__orderdone(event, element) {
    // Destructure necessary properties from APOData
    const { APOtype, CountriesList, onboardingData, step, APO_selectedData, lang, widgetLanguage } = this.APOData;
    const { bookingFormScreen } = widgetLanguage[lang];
    const { appointmentJSON } = APO_selectedData;

    const APO__insertOrderD = document.querySelector(".APO__insertOrderD"); // order detail insert content element
    const filldetails = document.querySelector(".APO__filldetails"); // order detail insert content element
    APO__insertOrderD.classList.remove("orderDminimized");
    APO__insertOrderD.classList.add("fillcontactDetail", "hide-scrollbar");

    // Get widget header Height and appy to APO__insertOrderD dynamically
    const bookingHeadHeight = document.querySelector(".booking__Head").clientHeight;
    APO__insertOrderD.style.top = `${bookingHeadHeight}px`;

    // Check if CountriesList is empty and populate it if needed
    if (CountriesList.length === 0) {
      // Display placeholder loading
      this.commonMethod.updateHandlebarsTemp(
        filldetails,
        this.handlebarsTemplate.placeholderLoading_form(),
        ""
      );
      
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        "Countries",
        "GET"
      );
      Object.assign(CountriesList, response.data);
    }

    // Check if AllcustomerID is not set and populate it if needed
    if (!this.APOData.AllcustomerID) {
      await this.fetchCustomerList();
    }

    // update step
    this.APOData.step = step + 1;

    if (APOtype === "GlobalEvents" || APOtype === "Class") {
      Object.assign(APO_selectedData, {
        bookSlotQuantity:  !appointmentJSON.usedSlots ? 0 : 1,
        allowMaxEventSlots: appointmentJSON.usedSlots <= 10 ? appointmentJSON.usedSlots : 10,
      });
  
      // Store unique guest email IDs globally
      this.APOData.guestEmails = [];
  
    }

    // Prepare the order detail object
    const orderDetail = {
      APO_selectedData,
      CountriesList,
      UserCountries: onboardingData.userCountry,
      goBackStep: this.APOData.step,
      APOtype,
      lang: bookingFormScreen
    };

    this.commonMethod.updateHandlebarsTemp(
      filldetails,
      this.handlebarsTemplate.template_fillContactDetail(),
      orderDetail
    );

    // Initialize custom select elements with search functionality
    $(".DropwithSearch").customSelect({ search: true,block: 'APO-customSelect' });

    // Attach event listeners for the booking form
    this.bookingForm.bookingForm_EventListeners();
  }
}
export default OrderDetails;
