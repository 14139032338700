import APIHandler from "./Api";
import GlobalProps from "./GlobalProps";
import HandlebarsTemplate from "./Handlebars-template";
import commonMethod from "./Common-methods";
import { loadStripe } from "@stripe/stripe-js";

class StripePayment  {
  constructor(paymentDetail) {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars template
    this.commonMethod = new commonMethod(); // instance of common method
    this.paymentDetail = paymentDetail; // instance of Handlebars template
    this.gblProps = new GlobalProps(); // instance of gobal props class
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
  }

  // Function to create a payment intent using appointment data
  async createPaymentIntent() {
    try {
      // Destructure necessary properties from APOData
      const { APO_selectedData, stripeData, onboardingData } = this.APOData;
      const { appointmentJSON } = APO_selectedData;

      const data = {
        amount: appointmentJSON.price,
        // currency: onboardingData.userCurrencyCode,
        currency: "USD",
        // stripeAccountId: stripeData.stripeAccountId,
        stripeAccountId: "acct_1PffoVFR6rCIFCnu",
      };
      const IntentsResponse = await this.apiHandler.PostAPI_PutAPI_method(
        "PaymentIntents",
        "POST",
        data
      );
      return IntentsResponse.data;
    } catch (error) {
      console.error("Error creating payment intent:", error);
      // throw new Error("Failed to create payment intent.");
    }
  }
  // Function to setup Stripe and handle the payment process
  async setupStripe() {
    try {
      // Destructure necessary properties from APOData
      const { APO_selectedData, stripeData, APOtype, step } = this.APOData;

      // update step
      this.APOData.step = (step + 1);

      // Load the Stripe object with your public key
    //   const stripe = await loadStripe(stripeData.StripePublishableKey);
      const stripe = await loadStripe("pk_test_51PTiWXJyWJAMyGw1KE7jab35H5lCpdZpPtfa3pdTLl4pjmFsGcwLU8Frcxn5eXr5VqflIeWiuxO0J8aupCV0EBF300xhT42u1E");

      // appointment booking/pay with satus
      this.APOData.selectedPaymentMethod = "pay_with_card";

      // Create the payment intent and get the client secret
      const response = await this.createPaymentIntent();
      const clientSecret = response.client_secret;

      // Select the booking content element and update it with the payment template
      const APO__content = document.querySelector(".APO__content");
      this.commonMethod.updateHandlebarsTemp(APO__content, this.handlebarsTemplate.template_StripeCheckout(), {
        ...APO_selectedData,
        paymentModeName: "stripe",
        goBackStep: this.APOData.step,
      });

      // update the header title
      this.commonMethod.updateBoxTitle("Make a payment");

      // Setup payment form elements
      const form = document.getElementById("stripe-checkout-form");
      const submitButton = document.getElementById("submit");
      const errorMessage = document.getElementById("error-message");
      const paymentMessage = document.getElementById("payment-message");

      // Define Stripe elements options
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const appearance = {
        theme: "stripe",
      };
      const elements = stripe.elements({ clientSecret, appearance });

      // Create and mount the payment element
      this.APOData.stripePaymentElement = elements.create("payment", options);
      this.APOData.stripePaymentElement.mount("#payment-element");

      // Handle form submission
      form.addEventListener("submit", async (event) => {
        event.preventDefault();
        submitButton.disabled = true;

        // loader
        this.commonMethod.setBtnLoader(true, event.submitter, [27]);

        try {
          if (this.APOData.selectedPaymentMethod === "skip_payment") {
            await this.paymentDetail.APO__bookappointment(false);
          } else {
            const { error, paymentIntent } = await stripe.confirmPayment({
              elements,
              confirmParams: {}, // Do not set return_url
              redirect: "if_required", // Use 'if_required' to stay on the same page
            });

            if (error) {
              // Show error message
              errorMessage.innerText = error.message;
              errorMessage.classList.remove("hidden");
            } else {
              handlePaymentStatus(paymentIntent);
            }
          }
          submitButton.disabled = false;
          this.commonMethod.setBtnLoader(false, event.submitter);
        } catch (error) {
          console.error("Payment confirmation error:", error);
          errorMessage.innerText = "An error occurred. Please try again.";
          errorMessage.classList.remove("hidden");
          submitButton.disabled = false;
          this.commonMethod.setBtnLoader(false, event.submitter);
        }
      });

      const handlePaymentStatus = async (paymentIntent) => {
        if (paymentIntent.status === "succeeded") {
          await this.paymentDetail.APO__bookappointment(paymentIntent);
        } else if (paymentIntent.status === "processing") {
          paymentMessage.textContent = "Your payment is processing.";
        } else {
          paymentMessage.textContent =
            "Payment not successful. Please try again.";
        }
        paymentMessage.classList.remove("hidden");
        errorMessage.classList.add("hidden");
      };
    } catch (error) {
      console.error("Setup Stripe error:", error);
    }
  }
}

export default StripePayment;
