import APIHandler from "./Api";
import HandlebarsTemplate from "./Handlebars-template";
import CalendarDataTime from "./Calendar";
import GlobalProps from "./GlobalProps";
import commonMethod from "./Common-methods";
import OrderDetails from "./OrderDetails";
import BusinessData from "./BusinessData";
import BookingRules from "./BookingRules";
import ProductCategory from "./ProductCategory";
import SingleStaff from "./SingleStaff";
import MultiLocation from "./MultiLocation";
import { find } from "lodash-es";
import Handlebars from "handlebars";

class Booking {
  constructor(key) {
    this.options = key;
    this.apiHandler = new APIHandler(); // instance of APIHandler
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
    this.calendarDataTime = new CalendarDataTime(); // instance of calendar
    this.commonMethod = new commonMethod(); // instance of common methods
    this.orderDetails = new OrderDetails(); // instance of order detail
    this.businessData = new BusinessData(this); // pass the current Booking instance
    this.bookingRules = new BookingRules(this); // Booking rule instance
    this.productCategory = new ProductCategory(this); // product Category instance
    this.singleStaff = new SingleStaff(); // instance of single staff
    this.multiLocation = new MultiLocation(this); // multi location instance
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment data

    this.bindMethods();
  }

  async init() {
    const isValidKey = await this.validateKey();
    if (!isValidKey) {
      console.log("Initialization aborted due to invalid key.");
      return;
    }

    this.createPluginLayout();
    this.addEventListeners();
  }

  bindMethods() {
    window.APO__chooseAppointment = this.APO__chooseAppointment.bind(this); // Bind method class instance
    window.APO__chooseStaff = this.APO__chooseStaff.bind(this); // Bind method class instance
    window.goBackStep = this.goBackStep.bind(this); // Bind method class instance
    // window.APO__bookanother = this.APO__bookanother.bind(this); // Bind method class instance
    window.APO__TypeBoxHTML = this.APO__TypeBoxHTML.bind(this); // Bind appointment type screen
    window.newAPOandSearchScreen = this.newAPOandSearchScreen.bind(this); // Bind Book new and Search appointment screen

    //svg icon's
    this.ANGLELEFT = process.env.ANGLELEFT;
  }

  async validateKey() {
    //const apiHandler = new APIHandler();
    const keyValidate = {
      token: this.options.key,
      validity: "Long Live",
    };

    const keyResponse = await this.apiHandler.PostAPI_PutAPI_method(
      "ChatWindowToken",
      "POST",
      keyValidate
    );
    if (keyResponse.status === 400) {
      return false;
    } else {
      const {
        accesstoken,
        isCashConnected,
        isPayPalConnected,
        isStripeConnected,
        stripePublishableKey,
        stripeAccountId,
      } = keyResponse.data;

      // Set the token in the APIHandler instance and update the cookie
      const newTK = `Bearer ${accesstoken}`;
      document.cookie = "tk" + "=" + (newTK || "");
      this.apiHandler.token = newTK;

      //store key
      this.APOData.key = this.options.key;

      // Update payment modes and Stripe data if connected
      this.APOData.paymentModeList = {
        isCashConnected,
        isPayPalConnected,
        isStripeConnected,
      };

      // update accept payments perms
      if (isStripeConnected) {
        this.APOData.stripeData = {
          stripePublishableKey,
          stripeAccountId,
        };
      }

      //fetch booking rules
      await this.bookingRules.fetchBookingRules();
      return true;
    }
  }

  // Method to handle the back action
  async goBackStep(event, element) {
    event.preventDefault(); // Prevent the default link action

    // Destructure necessary properties from StyleObj and APOData
    const { APOtype, step, APO_selectedData, locationList } = this.APOData;

    // Decrease the current step
    this.APOData.step = step - 2;

    // Get the back step method from the href attribute of the element
    let backMethod = parseInt(element.getAttribute("href"));

    switch (backMethod) {
      case 0:
        this.newAPOandSearchScreen();
        break;
      case 1:
      case 2:
        this.APO__TypeBoxHTML();
        APO_selectedData.appointmentJSON = null;
        APO_selectedData.staffJSON = null;
        break;
      // case 2:
      //   await this.multiLocation.bindLocationLayout(locationList);
      //   break;
      case 3:
        this.productCategory.APO__AppointmentLayout(this.APOData[APOtype]);
        break;
      case 4:
        this.APO__chooseAppointment();
        break;
      case 5:
        this.APO__chooseDateTime();
        break;
    }
  }

  createPluginLayout() {
    // Destructure necessary properties from APOData
    const { Bookingrules } = this.APOData;

    // Extract booking rules
    const IsHideAppointUsBranding = Bookingrules.bookingRulesLists?.find(
      (rule) => rule.keyName === "IsHideAppointUsBranding"
    )?.isSelected;

    const defaultsOptions = {
      buttonPosition: "right",
      text: "Book Now",
      backgroundColor: "#3C65F5",
      borderRadius: "5px",
      color: "#fff",
      hoverbackgroundColor: "#434856",
      hovercolor: "#fff",
      width: "160px",
      height: "62px",
      lang: "en" 
    };

    // Check and assign defaults
    for (const key in defaultsOptions) {
      if (this.options[key] === undefined || this.options[key] === "") {
        this.options[key] = defaultsOptions[key];
      }
    }

    this.APOData.lang = this.options.lang;

    // Prepare the data for the Handlebars template
    const templateData = {
      buttonOptions: this.options,
      IsHideAppointUsBranding,
    };

    // Compile Handlebars template
    const compiledTemplate = Handlebars.compile(
      this.handlebarsTemplate.template_APOPlugin()
    );

    // Update the inner HTML of the element with the compiled template
    const html = compiledTemplate(templateData);

    // Append the form to the body (or another container element)
    document.body.insertAdjacentHTML("beforeend", html);
    this.commonMethod.APO_opencloseBookingbox();
  }

  APO_addBackbtn() {
    //add back button
    const APO__back = document.querySelector(".APO__back");
    if (!APO__back) {
      const booking__Head = document.querySelector(".booking__Head");
      const backArrowHtml = `<a href="0" onclick="return goBackStep(event, this)" class="APO__back APO-text-lg APO-font-light silver__font flex items-center APO-gap-1">${this.ANGLELEFT}</a>`;
      booking__Head.insertAdjacentHTML("afterbegin", backArrowHtml);
    }
  }

  addEventListeners() {
    const bookingToggle = document.querySelector(".booking-toggle");
    const APO_bookingBox = document.getElementById("APO_bookingBox");

    bookingToggle.addEventListener("click", async () => {
      this.commonMethod.toggleBookingBox(APO_bookingBox, bookingToggle, true);
      // await this.businessData.APO__AppointmentBookedList();
      this.newAPOandSearchScreen();
    });
  }

  async newAPOandSearchScreen() {
    // Select the booking content element and box title
    const APO__content = document.querySelector(".APO__content");
    const APO__back = document.querySelector(".APO__back");

    // Display placeholder loading
    this.commonMethod.updateHandlebarsTemp(
      APO__content,
      this.handlebarsTemplate.placeholderLoading_boxedlayout(),
      ""
    );

    // Update Language preference
    const {key, lang} = this.options
    this.APOData.widgetLanguage = await this.commonMethod.fetchlanguageJson(key, lang);

    const {homeScreen} = this.APOData.widgetLanguage[lang]

    // update the header title
    this.commonMethod.updateBoxTitle(homeScreen.headerTitle);

    this.commonMethod.updateHandlebarsTemp(
      APO__content,
      this.handlebarsTemplate.template_NewAPOandSearchScreen(),
      {lang: homeScreen}
    );

    // Remove back arrow if it exists
    if (APO__back) {
      APO__back.remove();
    }
  }

  async APO__TypeBoxHTML() {
    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");

    try {
      // Display placeholder loading
      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.placeholderLoading_boxedlayout(),
        ""
      );

      // Fetch onboarding data if not already available
      if (!this.APOData.onboardingData) {
        await this.productCategory.fetchOnboardingData();
      }

      // Render appointment type list
      this.appointmentTypeList(APO__content, this.APOData.onboardingData);
    } catch (error) {
      console.error("Error in APO__TypeBoxHTML:", error);

      // Destructure necessary properties from APOData
      const {lang, widgetLanguage} = this.APOData
      const { APOtypeScreen } = widgetLanguage[lang];

      // Update UI with retry option
      this.commonMethod.handleErrorWithRetry(
        true,
        APO__content,
        {lang: APOtypeScreen},
        this.APO__TypeBoxHTML.bind(this)
      );
    }
  }

  async appointmentTypeList(APO__content, data) {
    try {
      // Generate the list of appointment types
      this.APOData.APOtypeList = await this.commonMethod.getAvailableAppointmentTypes(data);;
      
      // Destructure necessary properties from APOData
      const { APOtypeList, Bookingrules, lang,  widgetLanguage } = this.APOData;
      const { APOtypeScreen, APORuleMessage } = widgetLanguage[lang];

      // Initialize the step to 0
      this.APOData.step = 0;

      // Ensure the back button is present if the type is "Service"
      if (!document.querySelector(".APO__back")) {
        this.APO_addBackbtn();
      }

      // Extract booking rules
      const IsBookAppointmentVisible = Bookingrules.bookingRulesLists?.find(
        (rule) => rule.keyName === "IsBookAppointmentVisible"
      )?.isSelected;

      // If only one appointment type is available and visible, auto-select it
      if (APOtypeList.length === 1 && IsBookAppointmentVisible) {
        this.APOData.APOtype = APOtypeList[0].typeName;
        this.businessData.APO__selectType();
        return;
      }

      // Update the box title
      this.commonMethod.updateBoxTitle(APOtypeScreen.headerTitle);

      // Prepare the data for the Handlebars template
      const templateData = {
        goBackStep: this.APOData.step,
        APOtypeList,
        IsBookAppointmentVisible,
        lang: { ...APOtypeScreen, ...APORuleMessage },
      };

      this.commonMethod.updateHandlebarsTemp(
        APO__content,
        this.handlebarsTemplate.template_APOType(),
        templateData
      );

    } catch (error) {
      console.error("Error setting appointment types:", error);
    }
  }

  async APO__chooseAppointment(event, element) {
    try {
      // Prevent default behavior if the event exists
      event?.preventDefault();

      // Destructure necessary data from APOData
      let { APOtype, APO_selectedData } = this.APOData;

      // Handle appointment selection when the element is provided
      if (element) {
        const appointmentId = element.getAttribute("href");
        const appointmentData = find(this.APOData[APOtype], { id: appointmentId, });

        if (!appointmentData) {
          console.error(`No appointment found for ID: ${appointmentId}`);
          return;
        }

        // Assign the selected appointment data
        APO_selectedData.appointmentJSON = appointmentData;
      }

      // Navigate based on appointment type
      switch (APOtype) {
        case "Service":
          this.APO__chooseService();
          break;
        case "GlobalEvents":
        case "Class":
          this.orderDetails.APO_orderdetailbox();
          break;
        // case "Class":
        //   this.APO__chooseDateTime();
        //   break;
      }
    } catch (error) {
      console.error("Error choosing appointment:", error);
    }
  }

  async APO__chooseService() {
    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");
    try {
      // Destructure necessary properties from APOData
      const {
        APO_selectedData,
        onboardingData,
        Bookingrules,
        step,
        selectedLocation,
        lang,
        widgetLanguage
      } = this.APOData;
      const { professionalScreen, APORuleMessage } = widgetLanguage[lang];
      const { staffMembersList } = onboardingData;


      // Extract booking rules
      const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find(
        (rule) => rule.keyName === "IsSkipTeamMembers"
      )?.isSelected;
      const IsAssignAnyTeamMember = Bookingrules.bookingRulesLists.find(
        (rule) => rule.keyName === "IsAssignAnyTeamMember"
      )?.isSelected;

      // Filter visible staff and check single staff case
      const filteredVisibleStaff = staffMembersList.filter(
        (list) => list.isMemberCalenderShow
      );
      const isSingleVisibleStaff = filteredVisibleStaff.length === 1;

      // Fetch provider count data if needed
      const [firstProvider] = isSingleVisibleStaff
        ? filteredVisibleStaff
        : await this.singleStaff.fetchProviderCountData(selectedLocation.id);

      // update step
      this.APOData.step = isSingleVisibleStaff || IsSkipTeamMembers ? step : step + 1;

      const templateData = {
        goBackStep: this.APOData.step,
        staffList: staffMembersList,
        IsAssignAnyTeamMember: !isSingleVisibleStaff && filteredVisibleStaff,
        lang: {...professionalScreen, ...APORuleMessage}
      };

      if (isSingleVisibleStaff || IsSkipTeamMembers) {
        // Assign automatically based on sorted providers
        APO_selectedData.staffJSON = firstProvider;
        this.APO__chooseDateTime();
      } else if (IsAssignAnyTeamMember) {
          // Assign staff with the lowest booking count
          templateData.assignAnyStaff = firstProvider;

          this.commonMethod.updateHandlebarsTemp(
            APO__content,
            this.handlebarsTemplate.template_APOStaff(),
            templateData
          );

          // update the header title
          this.commonMethod.updateBoxTitle(professionalScreen.headerTitle);
        }
    } catch (error) {
      console.error("Error in APO__chooseService:", error);

      // Destructure necessary properties from APOData
      const { lang, widgetLanguage } = widgetLanguage;
      const { professionalScreen } = widgetLanguage[lang];

      // Update UI with retry option
      this.commonMethod.handleErrorWithRetry(
        true,
        APO__content,
        {lang: professionalScreen},
        this.APO__chooseService.bind(this)
      );
    }
  }

  APO__chooseStaff(event, element) {
    event.preventDefault();

    // Destructure necessary properties from APOData
    const { onboardingData, APO_selectedData } = this.APOData;

    // Filter the array to find the object with the desired id
    const staffId = element?.getAttribute("href");
    const staffJSON = find(onboardingData.staffMembersList, { id: staffId });

    if (staffJSON) {
      // Assign the selected staff to APO_selectedData
      APO_selectedData.staffJSON = staffJSON;

      // Proceed to the next step (choose date/time)
      this.APO__chooseDateTime();
    } else {
      console.error(`No staff member found for ID: ${staffId}`);
      return;
    }
  }

  APO__chooseDateTime() {
    // Destructure necessary properties from APOData
    const { APOtype, step, onboardingData, reschedule, lang, widgetLanguage, locationList } = this.APOData;
    const {calendarScreen, APORuleMessage} = widgetLanguage[lang];
    const { staffMembersList } = onboardingData;

    // Select the booking content element
    const APO__content = document.querySelector(".APO__content");

    // update step
    this.APOData.step = reschedule ? 0 : step + 1;

    const filteredVisibleStaff = staffMembersList.filter(
      (list) => list.isMemberCalenderShow
    );

    // Prepare template data
    const templateData = {
      goBackStep: this.APOData.step,
      APOtype: APOtype,
      filteredVisibleStaff: filteredVisibleStaff.length,
      lang: APORuleMessage
    };

    // Update the content using the template
    this.commonMethod.updateHandlebarsTemp(
      APO__content,
      this.handlebarsTemplate.template_Calendar(),
      templateData
    );

    // update the header title
    this.commonMethod.updateBoxTitle(calendarScreen.headerTitle);

    // Initialize the date picker after template rendering
    if (filteredVisibleStaff.length) this.calendarDataTime.initdatepicker();
  }
}

window.Booking = Booking;

// Export the Booking class
export default Booking;
