import Handlebars from "handlebars";
import HandlebarsTemplate from "./Handlebars-template";
import HandlebarsHelper from "./Handlebars-helper";
import GlobalProps from "./GlobalProps";
import moment from "moment";
import "moment-timezone";
import LanguageJson from "./languageJson.json";

class commonMethod {
  constructor() {
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars Template
    this.handlebarsHelper = new HandlebarsHelper(); // instance of Handlebars Helper
    this.gblProps = new GlobalProps(); // instance of calendar
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    window.APO_opencloseBookingbox = this.APO_opencloseBookingbox.bind(this); // Bind method class instance
  }

  async setTimezoneGlobally(data) {
    // Globally set the business time zone
    const businessTimeZone = data.businessAddressList[0].timeZone;
    this.APOData.businessTimeZone = businessTimeZone;
    moment.tz.setDefault(businessTimeZone);
  }

  updateBoxTitle(title) {
    // Select the title element
    const bookingBox__title = document.querySelector(".bookingBox__title");

    // Update the title using Handlebars template
    this.updateHandlebarsTemp(
      bookingBox__title,
      this.handlebarsTemplate.template_BoxTitle(),
      { bookingBtitle: title.toLowerCase() }
    );
  }

  updateHandlebarsTemp(element, templateFunc, data) {
    // Check if the element and template function are valid
    if (!element) {
      console.error("Element for updating template is not found.");
      return;
    }

    // Update the back button href if goBackStep is defined and APO__back exists
    const APO__back = document.querySelector(".APO__back");
    if (data.goBackStep >= 0 && APO__back) {
      APO__back.href = data.goBackStep;
    }
    // Compile Handlebars template
    const compiledTemplate = Handlebars.compile(templateFunc);

    // Register Handlebars helpers if necessary
    this.handlebarsHelper.registerHelper();

    // Update the inner HTML of the element with the compiled template
    const html = compiledTemplate(data);
    element.innerHTML = html;
  }

  APO__contentHeight() {
    const insertOorderD_H = document.querySelector(".APO__insertOrderD");
    const bookingContent = document.querySelector(".booking-content");
    bookingContent.style.paddingBottom = `${
      insertOorderD_H.clientHeight + 10
    }px`;
  }

  APO_opencloseBookingbox(event, element) {
    const bookingToggle = document.querySelector(".booking-toggle");
    const APO_bookingBox = document.getElementById("APO_bookingBox");
    this.toggleBookingBox(APO_bookingBox, bookingToggle, false);
  }

  toggleBookingBox(wrapper, toggle, show) {
    if (show) {
      wrapper.classList.remove("hide");
      wrapper.classList.add("show");
      toggle.classList.add("hidden");
    } else {
      wrapper.classList.remove("show");
      wrapper.classList.add("hide");
      toggle.classList.remove("hidden");
    }
  }

  async getAvailableAppointmentTypes(data) {
    const appointmentTypeConfig = [
      {
        condition: data.isServiceAdded,
        typeName: "Service",
        displayName: "Services",
        icon: `<svg class="svg-inline--fa fa-bell-concierge APO__typeicon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell-concierge" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24h16v33.3C119.6 157.2 32 252.4 32 368H480c0-115.6-87.6-210.8-200-222.7V112h16c13.3 0 24-10.7 24-24s-10.7-24-24-24H256 216zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z"></path></svg>`,
      },
      // {
      //   condition: data.isClassAdded,
      //   typeName: "Class",
      //   displayName: "Classes",
      //   icon: `<svg class="svg-inline--fa fa-book-open-reader APO__typeicon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open-reader" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M160 96a96 96 0 1 1 192 0A96 96 0 1 1 160 96zm80 152V512l-48.4-24.2c-20.9-10.4-43.5-17-66.8-19.3l-96-9.6C12.5 457.2 0 443.5 0 427V224c0-17.7 14.3-32 32-32H62.3c63.6 0 125.6 19.6 177.7 56zm32 264V248c52.1-36.4 114.1-56 177.7-56H480c17.7 0 32 14.3 32 32V427c0 16.4-12.5 30.2-28.8 31.8l-96 9.6c-23.2 2.3-45.9 8.9-66.8 19.3L272 512z"></path></svg>`,
      // },
      {
        condition: data.isGlobalEventAdded,
        typeName: "GlobalEvents",
        displayName: "Event planning",
        icon: `<svg class="svg-inline--fa fa-calendar-check APO__typeicon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path></svg>`,
      },
    ];

    return appointmentTypeConfig.filter(({ condition }) => condition);
  }

  setBtnLoader(isLoading, targetElement, size = [16]) {
    if (!targetElement) return; // Ensure targetElement is valid

    if (!isLoading) {
      const originalContent = targetElement.getAttribute(
        "data-original-content"
      );
      if (originalContent) {
        targetElement.innerHTML = originalContent;
      }
      targetElement.removeAttribute("data-original-content");
      targetElement.disabled = false; // Re-enable the button
    } else {
      if (!targetElement.getAttribute("data-original-content")) {
        targetElement.setAttribute(
          "data-original-content",
          targetElement.innerHTML
        );
      }
      const loaderHtml = `<span class="btn__loader APO__spin block APO-mx-auto" style="width:${size[0]}px;"></span>`;
      targetElement.innerHTML = loaderHtml;
      targetElement.disabled = true; // Disable the button to prevent multiple clicks
    }
  }

  // Helper function to show the loader
  showLoader(screenmask) {
    screenmask.classList.remove("hide");
    screenmask.classList.add("show");
  }

  // Hide screen mask and reset loader
  hidescreenmask(loader, screenmask, removeClass) {
    screenmask.classList.remove("show");
    screenmask.classList.add("hide");
    loader.classList.remove(removeClass);
    loader.classList.add("APO__spin");
  }

  handleErrorWithRetry(TryAgain, element, RetryContent, retryFunction) {
    // Render the error template with the retry option
    this.updateHandlebarsTemp(
      element,
      this.handlebarsTemplate.errorTemplate(),
      {
        lang: RetryContent.lang,
        showTryAgain: TryAgain, // Flag to show the "Try Again" button
      }
    );

    // Attach the click event dynamically to the "Try Again" button
    const tryAgainBtn = document.querySelector(".tryAgainBtn");
    if (tryAgainBtn) {
      tryAgainBtn.addEventListener("click", () => {
        retryFunction.call(this); // Call the passed function dynamically
      });
    }
  }

  async fetchlanguageJson(key, lang) {
    try {
      // Find user data or fallback to default
      let userLangData =
        LanguageJson.find((item) => item.id === key) ||
        LanguageJson.find((item) => item.id === "default");

      // Ensure userLangData and language exist
      return userLangData?.languages || null;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }
}
export default commonMethod;
