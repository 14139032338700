import APIHandler from "./Api";
import commonMethod from "./Common-methods";
import HandlebarsTemplate from "./Handlebars-template";
import HandlebarsHelper from "./Handlebars-helper";
import OrderDetails from "./OrderDetails";
import SingleStaff from "./SingleStaff";
import GlobalProps from "./GlobalProps";
import moment from "moment";
import { toZonedTime } from "date-fns-tz";

class CalendarDataTime {
  constructor() {
    this.commonMethod = new commonMethod(); // instance of common method
    this.handlebarsHelper = new HandlebarsHelper(); // instance of Handlebars Helper
    this.handlebarsTemplate = new HandlebarsTemplate(); // instance of Handlebars template
    this.orderDetails = new OrderDetails(); // instance of Handlebars template
    this.singleStaff = new SingleStaff(); // instance of single staff
    this.gblProps = new GlobalProps(); // instance of gobal props class
    this.APOData = this.gblProps.APOData; // appointment Data

    this.bindMethods();
  }

  bindMethods() {
    this.apiHandler = new APIHandler(); // instance of APIHandler
    window.APO__Bookingslots = this.APO__Bookingslots.bind(this); // Bind method class instance
  }

  async fetchSelectedStaffData(staffId, locId) {
    try {
      // Call the API to get events based on the staff ID
      const response = await this.apiHandler.GetAPI_deleteAPI_method(
        `EventsByStaffId?StaffId=${staffId}&LocationId=${locId}`,
        "GET"
      );

      // Validate the API response to ensure it contains data
      if (!response?.data || !response.data.length) {
        return [];
      }

      // Return the fetched booking list data
      return response.data;
    } catch (error) {
      console.error("Error fetching Selected Staff Data:", error);
    }
  }

  // Function to handle team data and apply relevant booking rules
  // async handleTeamDataAndRules() {
  //   const { APO_selectedData, Bookingrules } = this.APOData;
  //   const { staffJSON } = APO_selectedData;

  //   // Filter for 'IsSkipTeamMembers' rule to determine if team members should be skipped
  //   const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsSkipTeamMembers");

  //   // If the rule is selected and no staff is specified, auto-select the first provider from the ProviderCount API
  //   if (IsSkipTeamMembers.isSelected) {
  //     try {
  //       const ProviderBookingCount = await this.apiHandler.GetAPI_deleteAPI_method("ProviderCount", "GET");
  //       // Ensure the response contains valid provider data
  //       if (ProviderBookingCount?.data && ProviderBookingCount.data.length) {
  //         // Sort the array based on totalCount (ascending)
  //         ProviderBookingCount.data.sort((a, b) => a.totalCount - b.totalCount);

  //         console.log(ProviderBookingCount.data)
  //         ProviderBookingCount.data[0].id; // Select the first provider's ID
  //       }
  //     } catch (error) {
  //       console.error("Error fetching provider count data:", error);
  //     }
  //   }

  //   // Fetch and update the selected staff ID array if staffJSON is present
  //   if (staffJSON) {
  //     this.selectedStaffIdArray = await this.fetchSelectedStaffData();
  //   }
  // }

  async APO_previewDate(businessHours) {
    // APOData.utcTime = await getUtcTime();
    const { businessTimeZone } = this.APOData;
    const selectedDay = toZonedTime(moment().format(), businessTimeZone);
    // Use Moment.js to work with the UTC time

    const today = moment(selectedDay).format("dddd");
    const todayIndex = businessHours.findIndex((day) => day.day === today);
    const dayMap = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };

    for (let i = 0; i < businessHours.length; i++) {
      const dayIndex = (todayIndex + i) % businessHours.length;
      const dayData = businessHours[dayIndex];

      if (dayData.isOpened) {
        const daysToAdd = (dayMap[dayData.day] - todayIndex + 7) % 7;
        // return selectedDay.clone().add(daysToAdd, "days");
        return moment(selectedDay).clone().add(daysToAdd, "days");
      }
    }

    return null; // No open days found
  }

  async initdatepicker() {
    const self = this;
    let isInitialized = true; // Track initialization state

    // Destructure necessary properties from APOData
    const { APO_selectedData, businessTimeZone, selectedLocation } =
      this.APOData;
    const { staffJSON } = APO_selectedData;

    // Fetch and update the selected staff working HoursList as per Location id
    if (staffJSON) {
      staffJSON.businessHoursList =
        await this.singleStaff.fetchStaffHoursByLocation(
          staffJSON.id,
          selectedLocation.id
        );
    }

    const { businessHoursList } = staffJSON;

    // find the open day from provider business hours
    let openedDay = await this.APO_previewDate(businessHoursList);

    // Determine the initially selected day (default to tomorrow's date if not set)
    // let selectedDay = moment(new Date());
    APO_selectedData.date = openedDay;

    // Generate a comma-separated list of disabled days based on business hours
    const disabledDays = businessHoursList
      .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
      .filter((day) => day !== null)
      .join(",");

    $("#APO__datepicker")
      .datepicker({
        daysOfWeekDisabled: disabledDays,
        format: "mm-dd-yyyy",
        startDate: toZonedTime(moment().format(), businessTimeZone),
        weekStart: 1,
        todayHighlight: false,
        templates: {
          leftArrow: `${process.env.ANGLELEFT}`,
          rightArrow: `${process.env.ANGLERIGHT}`,
        },
        beforeShowDay: function (date) {
          //return {
          //  content: `<span>${new Date(date).getDate()}</span>`
          //}
        },
        beforeShowMonth: function (date) {
          return {
            content: `<span class="month">wewewewew</span>`,
          };
        },
      })
      .on("changeDate", async function (e) {
        // const SDay = e.format("DD");
        let selectedDay = new Date(e.date);
        let updateSelectedDay = moment();
        // Update the moment date with the new date
        updateSelectedDay
          .year(selectedDay.getFullYear())
          .month(selectedDay.getMonth())
          .date(selectedDay.getDate());

        APO_selectedData.date = moment(updateSelectedDay).format();

        // Remove the 'today' class from all days in the date picker to avoid styling conflicts
        document
          .querySelectorAll(".datepicker-days .day")
          .forEach((day) => day.classList.remove("today"));

        // If not initializing, reapply team data and rules
        // if (!isInitialized) {
        //   // Fetch and update the selected staff ID array if staffJSON is present
        //   if (staffJSON) {
        //     self.selectedStaffIdArray = await self.fetchSelectedStaffData();
        //   }
        //   APO_selectedData.timeslot = null;
        //   self.orderDetails.APO_orderdetailbox();
        // } else {
        //   isInitialized = false; // Mark as not initializing after the first change
        // }

        APO_selectedData.timeslot = null;
        self.orderDetails.APO_orderdetailbox();

        // Update service duration based on the newly selected day
        self.serviceDuration(updateSelectedDay);
      });

    // Set the date picker to display the initially selected day
    if (openedDay) {
      $("#APO__datepicker").datepicker(
        "setDate",
        moment(openedDay).format("l")
      );
    }
  }

  // Function to check service duration
  serviceDuration(selectedDay) {
    const { staffJSON, appointmentJSON } = this.APOData.APO_selectedData;

    // Get day name from selectedDay (e.g., "Monday")
    const dayName = selectedDay.format("dddd");

    // Find business hours for the given day
    const businessHours = staffJSON.businessHoursList.find(
      (item) => item.day === dayName
    );

    // Return early if no business hours are found or if the business is closed
    if (!businessHours || !businessHours.isOpened) return;

    // Destructure business hours properties
    const { from, to, breakHours } = businessHours;

    // Format start and end times in "HH:mm" format
    // const startTime = moment(from).format("HH:mm");
    // const endTime = moment(to).format("HH:mm");

    // Convert the appointment duration to minutes
    const durationInMinutes = this.handlebarsHelper.convertTimeToMinutes(
      appointmentJSON.duration
    );
    const timeData = {
      from,
      to,
      duration: durationInMinutes,
      breakHours,
    };

    // Generate service time slots for the given day using time data
    this.generateServiceTimeSlots(timeData, selectedDay);
  }

  // Function to calculate service time slots
  async generateServiceTimeSlots(timeData, selectedDay) {
    // Destructure necessary data from APOData
    const { APO_selectedData, selectedLocation, lang, widgetLanguage} = this.APOData;
    const { staffJSON } = APO_selectedData;

    const { from, to, duration, breakHours } = timeData;

    // Select the DOM element where the appointment slots will be rendered
    const SlotEle = document.querySelector(".APO__BookingSlot");

    // Format the date as "Tuesday, June 24"
    const selectedDate = {
      selectedDate: moment(new Date(selectedDay)),
    };

    // Display placeholder loading
    this.commonMethod.updateHandlebarsTemp(
      SlotEle,
      this.handlebarsTemplate.placeholderLoading_Timeslot(),
      selectedDate
    );

    // Check if the selected day is today and compare the current time
    const isToday = moment().format("DD") === moment(selectedDay).format("DD");
    const isTodayDate = moment().format("l") <= moment(selectedDay).format("l");
    const currentTime = moment().format("HH:mm");
    // const isStartTimePassed = currentTime >= from;

    const availableServiceSlots = [];
    let bookedSlotsAndBreakHours = [];

    // Fetch selected staff booking list
    const selectedStaffBookingList = await this.fetchSelectedStaffData(
      staffJSON.id,
      selectedLocation.id
    );

    if (selectedStaffBookingList.length > 0) {
      // Filter bookings to include only those that match the selected day
      const filteredBookings = selectedStaffBookingList.filter(
        (booking) =>
          booking?.startDate &&
          moment(booking.startDate).format("l") ==
            moment(selectedDay).format("l")
      );

      // Map the filtered bookings to an array of objects with 'from' and 'to' times
      const bookedSlots = filteredBookings.map((booking) => ({
        from: booking.startDate,
        to: booking.endDate,
        bookedManual: true,
      }));

      // Combine booked slots with break hours
      bookedSlotsAndBreakHours = [...bookedSlots, ...breakHours];
    }

    // Initialize timeSlot with the start time, and iterate until it reaches the end time
    let timeSlot = moment(from, "HH:mm");
    const endMoment = moment(to, "HH:mm");

    while (timeSlot.isBefore(endMoment)) {
      const slotTime = timeSlot.format("HH:mm");

      // Check if the current slot falls within a break or booked slot
      const { isBreak, breakEndTime } = await this.isWithinBreakTime(
        slotTime,
        bookedSlotsAndBreakHours
      );

      // Add the slot to available slots if it's either not today or after the current time
      if (!isToday || !isTodayDate || currentTime < slotTime) {
        const fullTimestamp = moment(selectedDay).set({
          hour: timeSlot.hour(),
          minute: timeSlot.minute(),
        });
        availableServiceSlots.push({
          slot: slotTime,
          timestamp: fullTimestamp.unix(),
          break: isBreak,
        });
      }

      // If the slot is within a break, skip to the end of the break; otherwise, increment by duration
      timeSlot = timeSlot.clone().add("15", "minutes");
    }

    // Parse the date with Moment.js
    // const momentNewDate = moment(new Date(selectedDay));

    // Prepare the data for the Handlebars template
    const templateData = {
      ...selectedDate,
      Bookingslots: availableServiceSlots,
      lang: widgetLanguage[lang].APORuleMessage
    };

    this.commonMethod.updateHandlebarsTemp(
      SlotEle,
      this.handlebarsTemplate.template_BookingStols(),
      templateData
    );
  }

  // Function to check if the time is within break hours
  async isWithinBreakTime(time, breakHours) {
    if (!breakHours || breakHours.length === 0)
      return { isBreak: false, breakEndTime: null };
    let breakInfo = { isBreak: false, breakEndTime: null };
  
    // Iterate through break times and find if the time falls within any break period
    breakHours.forEach((breakTime) => {
      const breakStartTime = breakTime.bookedManual ? moment(breakTime.from).format("HH:mm") : breakTime.from;
      const breakEndTime = breakTime.bookedManual ?  moment(breakTime.to).format("HH:mm") : breakTime.to;
  
      if (time >= breakStartTime && time < breakEndTime) {
        breakInfo = { isBreak: true, breakEndTime: breakEndTime };
      }
      // return time >= breakStartTime && time < breakEndTime;
    });
    return breakInfo;
  }

  APO__Bookingslots(event, element) {
    const Timeslots = document.querySelectorAll(".APO__Timeslots");
    // Remove the 'selected' class from all slot
    Timeslots.forEach((slot) => {
      slot.classList.remove("selected");
    });
    // Add the 'selected' class to the clicked slot
    element.classList.add("selected");
    const { APO_selectedData } = this.APOData;
    APO_selectedData.timeslot = element.dataset.slot;
    this.orderDetails.APO_orderdetailbox();
  }
}

export default CalendarDataTime;
